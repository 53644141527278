import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const getTasks = async () => axios.get(`${apiUrl}/gettasks`);

const getTeams = async () => {
    try {
        const response = await axios.get(`${apiUrl}/getteams`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch teams");
    }
};

const getTaskQueues = async () => axios.get(`${apiUrl}/gettaskqueues`);

const getWorkers = async () => axios.get(`${apiUrl}/getworkers`);

const getTaskEmail = async (taskSid) =>
    axios.get(`${apiUrl}/getemail/${taskSid}`);

const reassignTask = async (taskSid, taskOptions) =>
    axios.post(`${apiUrl}/reassigntask`, { taskSid: taskSid, ...taskOptions });

const twilioService = {
    getWorkers,
    reassignTask,
    getTasks,
    getTaskQueues,
    getTeams,
    getTaskEmail,
};

export default twilioService;
