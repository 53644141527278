import axios from "../plugins/axios";

const endpointUrl = `${process.env.REACT_APP_CASENEST_API_URL}/user`;

const updateLastActive = () => axios.patch(`${endpointUrl}/lastActive`);

const updateInCall = ({ inCall }) =>
    axios.patch(`${endpointUrl}/inCall`, { inCall });

const getAdviceTypes = async (userId) => {
    try {
        const response = await axios.get(
            `${endpointUrl}/${userId}/adviceTypes`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e?.message || "Could not fetch advice types.");
    }
};

const setAdviceType = async ({ userId, adviceTypeId, isEnabled }) => {
    try {
        const response = await axios.post(
            `${endpointUrl}/${userId}/advicetypes/${adviceTypeId}/${isEnabled}`
        );
        return response.data;
    } catch (e) {
        return Promise.reject(e?.message || "Could not set user advice types.");
    }
};

const fetchTeamUsersForManager = async ({ managerId }) => {
    try {
        const response = await axios.get(
            `${endpointUrl}/getteamusersformanager/${managerId}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        Promise.reject(e?.message || "Could not fetch team users for manager");
    }
};

const setUserActive = ({ userId, isActive }) =>
    axios.post(
        `${endpointUrl}/setActive?userId=${userId}&isActive=${isActive}`
    );

const userService = {
    updateLastActive,
    updateInCall,
    getAdviceTypes,
    setAdviceType,
    fetchTeamUsersForManager,
    setUserActive,
};

export default userService;
