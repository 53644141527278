import { buildQueryStringList } from "../helpers/stringUtils";
import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const addCaseAdviser = ({ adviserId, caseId, isPrimary = false }) => {
    return axios.post(`${apiUrl}/addcaseadviser`, {
        adviserId,
        caseId,
        isPrimary,
    });
};

const setChargeable = ({ caseId, budgetedMinutes, isChargeable }) =>
    axios.post(`${apiUrl}/setcasechargeable`, {
        caseId,
        budgetedMinutes,
        isChargeable,
    });

const getETClaims = async (etCaseId) => {
    try {
        const response = await axios.get(`${apiUrl}/getetclaims/${etCaseId}`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch ET claims.");
    }
};

const getETCase = async (caseId) => {
    try {
        const response = await axios.get(`${apiUrl}/getetcase/${caseId}`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch ET case.");
    }
};

const updateETCase = async (eTCase) => {
    try {
        const response = await axios.post(`${apiUrl}/updateetcase`, eTCase);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not update ET case.");
    }
};

const addETClaim = async (caseId, eTClaim) => {
    try {
        const response = await axios.post(`${apiUrl}/addclaim/${caseId}`, eTClaim);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not add new ET claim.");
    }
};

const updateETClaim = async (caseId, eTClaim) => {
    try {
        const response = await axios.post(`${apiUrl}/updateclaim/${caseId}`, eTClaim);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not update ET claim.");
    }
};

const deleteETClaim = async (caseId, claimId) => {
    try {
        await axios.post(`${apiUrl}/deleteclaim/${caseId}/${claimId}`);
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not add delete ET claim.");
    }
};

const updateBudgetedMinutes = ({ caseId, budgetedMinutes }) => axios.post(`${apiUrl}/updatebudgetedminutes/${budgetedMinutes}/${caseId}`);

const makeCaseActivityChargeable = ({ caseActivityId, isChargeable }) =>
    axios.post(`${apiUrl}/setcaseactivitychargeable`, {
        caseActivityId,
        isChargeable,
    });

const fetchCaseFiles = async (caseId, downloadOnly) => {
    try {
        const response = await axios.get(`${apiUrl}/document/getforcasedocumentviewer/${caseId}/${downloadOnly}`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch documents.");
    }
};

const getCaseActivityEventDetails = (caseId, caseActivityId) => axios.get(`${apiUrl}/getcaseactivityeventdetails/${caseId}/${caseActivityId}`);

const setCaseVisibleToContactsOnly = ({ caseId, isVisibleToContactsOnly }) =>
    axios.post(`${apiUrl}/setcasevisiblecontactsonly`, {
        caseId,
        isVisibleToContactsOnly,
    });

const setCaseActivityDescription = (caseActivityId, activityDescription) =>
    axios.post(`${apiUrl}/setcaseactivitydescription`, {
        caseActivityId,
        activityDescription,
    });

const getAllocatedCallTime = ({ caseId, callId }) => axios.get(`${apiUrl}/getallocatedcalltime/${caseId}/${callId}`);

const updateCaseFiles = async (caseId, downloadableOnly, caseFilesContent) => {
    try {
        const response = await axios.post(`${apiUrl}/updatecasefiles/${caseId}`, caseFilesContent);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not update case files.");
    }
};

const fetchCaseFilesTree = async (caseId, downloadOnly, order, orderBy) => {
    try {
        const response = await axios.get(`${apiUrl}/document/getcasedocumenttree/${caseId}/${downloadOnly}/${order}/${orderBy}`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch documents.");
    }
};

const updateCaseClosed = ({ caseId, caseOutcomeId, closureDate, adviserId }) =>
    axios.post(`${apiUrl}/updatecaseclosed`, {
        caseId,
        caseOutcomeId,
        closureDate,
        adviserId,
    });

const updateCaseOutcome = async ({ caseId, adviserId, caseOutcomeId }) =>
    axios.patch(`${apiUrl}/updatecaseoutcome`, {
        caseId,
        adviserId,
        caseOutcomeId,
    });

export const createSharepointFile = async (azureFileReference, caseId, fileName) => {
    try {
        const response = await axios.post(`${apiUrl}/sharepoint/createsharepointdocument`, {
            azureFileReference,
            caseId,
            fileName,
        });
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not create live document.");
    }
};

const getAllCaseActivities = async (
    caseId,
    valuesPerPage,
    pageNumber,
    dateFrom = null,
    dateTo = null,
    activityTypes = [],
    miscActivityTypes = [],
    isAction = false,
    isOutcome = false,
    chargeables = [],
    directions = [],
    userIds = [],
) => {
    let queryString = `?pageSize=${valuesPerPage}&includeFilters=true`;

    const encodedUserIds = userIds.map((id) => id.replace("+", "%2B")) ?? [];

    queryString += buildQueryStringList("activityTypes", activityTypes);
    queryString += buildQueryStringList("miscActivityTypes", miscActivityTypes);
    queryString += buildQueryStringList("userIds", encodedUserIds);
    queryString += buildQueryStringList("chargeables", chargeables);
    queryString += buildQueryStringList("directions", directions);

    queryString += `&pageSize=${valuesPerPage}`;

    if (pageNumber) queryString += `&pageNum=${pageNumber}`;

    if (dateFrom) queryString += `&fromDate=${dateFrom}`;

    if (dateTo) queryString += `&toDate=${dateTo}`;

    queryString += `&isAction=${isAction}`;
    queryString += `&isOutcome=${isOutcome}`;

    return axios.get(`${apiUrl}/getallcaseactivities/${caseId}${queryString}`);
};

const initiateCaseHistoryExport = ({ caseId }) => axios.post(`${apiUrl}/casehistoryexport/${caseId}`);

const downloadCaseHistoryExport = async ({ guid }) => {
    try {
        const response = await axios.get(`${apiUrl}/casehistoryexport/${guid}`, { responseType: "arraybuffer" });
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const getCaseHistoryExportStatus = async ({ guid }) => {
    try {
        const response = await axios.get(`${apiUrl}/casehistoryexport/status/${guid}`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const getCaseTime = async ({ caseId }) => {
    try {
        const { data } = await axios.get(`${apiUrl}/casetime/${caseId}`);
        return data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not get case time.");
    }
};
const getCases = async (criteria) => {
    try {
        const response = await axios.post(`${apiUrl}/getcases`, criteria);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const addPinnedCase = async (caseId) => 
    axios.post(`${apiUrl}/addpinnedcase/${caseId}`);

const deletePinnedCase = (caseId) =>
    axios.post(`${apiUrl}/deletepinnedcase/${caseId}`);


const caseService = {
    setChargeable,
    updateBudgetedMinutes,
    makeCaseActivityChargeable,
    addCaseAdviser,
    fetchCaseFiles,
    setCaseVisibleToContactsOnly,
    getCaseActivityEventDetails,
    setCaseActivityDescription,
    getETCase,
    updateETCase,
    addETClaim,
    getETClaims,
    deleteETClaim,
    updateETClaim,
    getAllocatedCallTime,
    updateCaseFiles,
    fetchCaseFilesTree,
    updateCaseClosed,
    updateCaseOutcome,
    getAllCaseActivities,
    initiateCaseHistoryExport,
    downloadCaseHistoryExport,
    getCaseHistoryExportStatus,
    getCaseTime,
    getCases,
    addPinnedCase,
    deletePinnedCase,
};

export default caseService;
