import React, { useState, useEffect } from "react";
import useFeatureFlag from "../hooks/useFeatureFlag";
import useInterval from "../hooks/useInterval";
import styled from "styled-components";
import Helmet from "react-helmet";
import { connect, useDispatch, useSelector } from "react-redux";
import CaseDetailsCard from "../cards/CaseDetailsCard";
import EmployeeCard from "../cards/EmployeeCard";
import ContactsCard from "../cards/ContactsCard";
import ExternalContactsCard from "../cards/ExternalContactsCard";
import CasePermissionCard from "../cards/CasePermissionCard";
import LawyerCard from "../cards/LawyerCard";
import WorkflowCard from "../cards/WorkflowCard";
import CaseHistoryCard from "../cards/CaseHistoryCard";
import TimeCard from "../cards/TimeCard";
import OrganisationCard from "../cards/OrganisationCard";
import MilestoneDocumentsCard from "../cards/MilestoneDocumentsCard";
import EvidenceCard from "../cards/EvidenceCard";
import InternalNoteCard from "../cards/InternalNoteCard";
import CaseDescriptionCard from "../cards/CaseDescriptionCard";
import CallCard from "../cards/CallCard";
import EmailCard from "../cards/EmailCard";
import MiscActivityCard from "../cards/MiscActivityCard";
import axios from "../plugins/axios";
import {
    setReplyContactsAction,
    setReplyNonContactsAction,
    setCaseViewStateAction,
    setCaseWindowStatus,
    addCaseEventAction,
    setCallNoteIssueFocus,
    setCallNoteAdviceFocus,
    setCallNoteActionFocus,
    setCallNoteOpen,
    setReplySubjectAction,
    setMilestoneViewerOpen,
    setInternalNoteOpen,
    setCaseDescription,
    setCallNoteText,
    setCallNoteCaseActivityId,
    setInternalNoteText,
    setInternalNoteCaseActivityId,
    setCallNoteMode,
    setEmailOpen,
    incrementEventCount,
    setCaseActivityIds,
    setCurrentCaseAction,
    setReplyEditorHtmlAction,
    setInboundEmailContactsAction,
    setInboundEmailNonContactsAction,
    setReplyIntCcsAction,
    setReplyingToAction,
    setMiscActivityOpen,
    setCaseResearchOpen,
    setBackdropOpen,
    setReplyPrecedentsAction,
    setCaseFilesTreeChanged,
    setCaseAttachmentsSelected,
    setDraftAttachmentsSelected,
    setAiGeneratedPrecedentIds,
    resetAiResponse,
} from "../redux/actions/caseActions";
import { setSnackAction } from "../redux/actions/snackActions";
import { setUserTimerRunning, setFullScreenEmail, openFileViewer } from "../redux/actions/userActions";
import DocumentDialog from "../components/DocumentDialog";
import { saveTimerExpired } from "../redux/actions/thunks";
import { Grid, Divider as MuiDivider, Typography, Tab as MuiTab, Tabs, Box, Button, Collapse, makeStyles, Paper } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import caseTabs from "../constants/caseTabs";
import { startCaseResearch } from "../services/caseResearchService";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import Files from "../components/Files";
import CaseFiles from "../components/CaseFiles";
import DraftFiles from "../components/DraftFiles";
import documentService from "../services/documentService";
import formatEmailSubject from "../helpers/formatEmailSubject";
import CaseActionsCard from "../cards/CaseActionsCard";
import { ETClaimDetails } from "../components/ETClaimDetails";
import TabPanel from "../components/TabPanel";
import CaseReplyAndPrecedentTabs from "../components/CaseReplyAndPrecedentTabs";
import CaseFilesTreeChangeDialog from "../components/dialogs/CaseFilesTreeChangeDialog";
import CaseHistory from "../components/CaseHistory";
import { useEmailDraft } from "../contexts/emailDraftContext";
import { DraftEmailsSummaryDialog } from "../components/dialogs/DraftEmailsSummaryDialog";
import EmailDrafts from "../components/EmailDrafts";
import CaseCappedContractCard from "../cards/CaseCappedContractCard";
import useAiResponse from "../hooks/useAiResponse";
import { aIPrecedentIdRegex, aIPrecedentTagRegex } from "../constants/regexPatterns";
import { pendingAiResponseStatus } from "../constants/pendingAiResponseStatus";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Tab = styled(MuiTab)`
    min-width: 0;
`;

const useStyles = makeStyles((theme) => ({
    contractExpiredAlert: {
        marginBottom: theme.spacing(4),
    },
    //This centers vertically for empty folders
    //Empty removes flex (centering) when on other tabs
    filePanel: {
        flex: "1 1 100%",
        display: "flex",
        flexDirection: "column",
        "&:empty": {
            flex: "none",
        },
        "& > div": {
            flex: "1 1 100%",
            display: "flex",
            flexDirection: "column",
        },
    },
    tabPaper: {
        marginTop: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

const Divider = styled(MuiDivider)(spacing);

const Case = ({
    caseState,
    setReplyContacts,
    setReplyNonContacts,
    setReplyEditorHtml,
    setInboundEmailContacts,
    setInboundEmailNonContacts,
    setCaseViewState,
    userState,
    setCaseWindowStatus,
    setSnackAction,
    setUserTimerRunning,
    addCaseEvent,
    setCallNoteIssueFocus,
    setCallNoteAdviceFocus,
    setCallNoteActionFocus,
    openFileViewer,
    setCallNoteOpen,
    setMilestoneViewerOpen,
    setReplySubject,
    setInternalNoteOpen,
    setCaseDescription,
    setCallNoteText,
    setCallNoteCaseActivityId,
    setInternalNoteText,
    setInternalNoteCaseActivityId,
    setCallNoteMode,
    setEmailOpen,
    setFullScreenEmail,
    incrementEventCount,
    setCaseActivityIds,
    setReplyingTo,
    setMiscActivityOpen,
    setReplyIntCcs,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { data: isCaseFilesEnabled } = useFeatureFlag("CaseFiles");
    const [documentGuid, setDocumentGuid] = useState(null);
    const [dismissedExpiredContractAlerts, setDismissedExpiredContractAlerts] = useState([]);
    const [openTabChangeConfirmDialog, setOpenTabChangeConfirmDialog] = useState(false);
    const [proposedTab, setProposedTab] = useState(null);
    const [contextEmail, setContextEmail] = useState({});
    const [replyLoading, setReplyLoading] = useState(false);
    const [pendingAiResponseEmail, setPendingAiResponseEmail] = useState(null);
    const caseId = caseState.currentCaseId;
    const c = caseState.cases[caseId];

    const { draftEmailSummaryDialogOpen, setDraftEmailSummaryDialogOpen, draftEmailsSummary, reset } = useEmailDraft();

    const currentTab = c && c.viewState.currentTab;

    const callNoteOpen = c && c.callNote.open;
    const internalNoteOpen = c && c.internalNote.open;
    const emailOpen = c && c.email.open;
    const miscActivityOpen = c && c.miscActivity.open;
    const { inCall } = useSelector((state) => state.flexReducer);
    const { pendingAiResponse } = useSelector((state) => state.caseReducer);

    const { getAiResponse, allowAiResponse } = useAiResponse();

    useEffect(() => {
        dispatch(setCurrentCaseAction(caseId));
    }, [dispatch, caseId]);

    useEffect(() => {
        const handleWindowFocus = () => {
            setCaseWindowStatus("focus");
        };
        const handleWindowBlur = () => {
            setCaseWindowStatus("blur");
        };

        setCaseWindowStatus("focus");
        window.addEventListener("focus", handleWindowFocus);
        window.addEventListener("blur", handleWindowBlur);

        return () => {
            setCaseWindowStatus("blur");
            window.removeEventListener("focus", handleWindowFocus);
            window.removeEventListener("blur", handleWindowBlur);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleConfirmTabChange = () => {
        setOpenTabChangeConfirmDialog(false);
        dispatch(setCaseFilesTreeChanged(caseId, false));
        setCurrentTab(proposedTab);
    };

    const handleSkipTabChange = () => {
        setOpenTabChangeConfirmDialog(false);
    };

    const handleTabChange = (e, newValue) => {
        if (userState.isUserTimerRunning) {
            setUserTimerRunning(false);
        }

        if (c.caseFilesTreeChanged) {
            setProposedTab(newValue);
            setOpenTabChangeConfirmDialog(true);
            return;
        }

        setCurrentTab(newValue);
    };

    useInterval({
        callback: () => dispatch(saveTimerExpired()),
        delay: 60000,
    });

    const contactsWithEmail = () => {
        let contacts = [];
        Object.values(c.contacts).forEach((contact) => {
            if ((contact.email || contact.email2) && !contact.disabled) contacts.push(contact);
        });
        return contacts;
    };

    const replyToSeekAdvice = (e) => {
        reset();

        setReplySubject(e.activityDescription, caseId);

        if (e.fromAddress) setReplyContacts(caseId, [{ email: e.fromAddress, name: e.person }]);

        setReplyingTo({
            ...e,
            subject: e.activityDescription,
        });

        goToReplyTab();
    };

    useEffect(() => {
        if (pendingAiResponse?.status === pendingAiResponseStatus.COMPLETE) {
            if (pendingAiResponse?.emailId === pendingAiResponseEmail?.email.emailId)
                loadEmptyReply(
                    {
                        ...pendingAiResponseEmail.email,
                        aiGeneratedResponse: pendingAiResponse.response,
                    },
                    pendingAiResponseEmail.isReplyAll
                );
            dispatch(resetAiResponse());
        }
    }, [pendingAiResponse, pendingAiResponseEmail]);

    const loadEmptyReply = (e, isReplyAll) => {
        let emailContent = "";

        if (allowAiResponse) {
            emailContent = getAiResponse({ email: e });

            if (!emailContent) {
                if (pendingAiResponse?.emailId === e.emailId && pendingAiResponse?.status === pendingAiResponseStatus.PENDING) {
                    setPendingAiResponseEmail({ email: e, isReplyAll });
                    return;
                }
            }
        }

        const precedentTags = emailContent.match(aIPrecedentTagRegex);
        const precedentIds = [];
        precedentTags && precedentTags.forEach((tag) => {
            precedentIds.push(parseInt(tag.match(aIPrecedentIdRegex)[0], 10));
            emailContent = emailContent.replaceAll(tag, "");
        });

        if (precedentIds.length > 0)
            dispatch(setAiGeneratedPrecedentIds(caseId, precedentIds));

        setReplySubject(formatEmailSubject(e.subject, caseId));       

        if (!!emailContent) setReplyEditorHtml(caseId, emailContent.replaceAll("\n", "<br>"));

        //e.person stores the email address received from
        if (e.person) {
            let toEmailAdds = [];
            //e.toAddress holds all other addresses stored in the "to"
            if (e.toAddress && isReplyAll) {
                var tmpToAdds = e.toAddress;

                let lastChar = tmpToAdds.slice(-1);
                if (lastChar === ";")
                    tmpToAdds = tmpToAdds.slice(0, -1);                

                toEmailAdds = tmpToAdds.split(";");
                toEmailAdds.push(e.person);
            } else {
                toEmailAdds = e.person;
            }

            const toEmailRecs = contactsWithEmail().filter((contactsWithEmail) => toEmailAdds.includes(contactsWithEmail.email));

            //if no known email addresses found then must be a non contact
            if (toEmailRecs.length === 0)
                setReplyNonContacts(caseId, [toEmailAdds]);
            else
                setReplyContacts(caseId, toEmailRecs);            

            if (isReplyAll) {
                //now set the unknown incoming addresses
                let names = toEmailRecs.map(item => item.email);

                //get the email addresses that are not known and have already been included in the above setReplyContacts
                let difference = toEmailAdds.filter((x) => !names.includes(x));
                setReplyNonContacts(caseId, difference);
            }

            //if replyall then set the known and unknown cc addresses
            let fromCCEmails = [];
            if (e.ccAddresses && isReplyAll) {
                var tmpCCAdds = e.ccAddresses;

                let lastChar = tmpCCAdds.slice(-1);
                if (lastChar === ";")
                    tmpCCAdds = tmpCCAdds.slice(0, -1);                

                fromCCEmails = tmpCCAdds.split(";");

                const toEmailRecs = contactsWithEmail().filter((contactsWithEmail) => fromCCEmails.includes(contactsWithEmail.email));
                setInboundEmailContacts(caseId, toEmailRecs);

                var names = toEmailRecs.map(item => item.email);

                let difference = fromCCEmails.filter((x) => !names.includes(x));
                setInboundEmailNonContacts(caseId, difference);
            }
        }

        goToReplyTab();
        setReplyLoading(false);
    };

    const newDraftEmail = () => {
        reset();

        setReplySubject(formatEmailSubject(null, caseId));

        goToReplyTab();
        setReplyLoading(false);
    };

    const goToReplyTab = () => {
        setCaseViewState(caseId, {
            ...c.viewState,
            mode: caseTabs.replying,
            currentTab: 5,
        });
    };

    const setReplyEmail = async (e) => {
        let response = await axios.get(`${apiUrl}/getemailbycaseactivityid/${e.caseActivityId}`);
        if (!response.data) {
            dispatch(setSnackAction("Couldn't retrieve the email. Please try again. If problem persists contact a member of IT support", "error"));
            return;
        }
        e = { ...response.data, ...e };
        setReplyingTo(e);

        return e;
    };

    const caseHistoryReply = async (e, isReplyAll = false) => {
        reset();
        setReplyLoading(true);

        if (userState.isUserTimerRunning)
            setUserTimerRunning(false);

        //reset first
        setReplyContacts(caseId, []);
        setReplyNonContacts(caseId, []);
        setReplyIntCcs(caseId, []);
        setInboundEmailNonContacts(caseId, null);
        setInboundEmailContacts(caseId, null);

        if (e.caseActivityId) {
            if (!!e.miscActivityCategory) {
                replyToSeekAdvice(e);
                return;
            }
            e = await setReplyEmail(e);
        }

        loadEmptyReply(e, isReplyAll);
    };

    const caseHistoryForward = async (e) => {
        reset();

        if (userState.isUserTimerRunning) setUserTimerRunning(false);

        if (e.caseActivityId) {
            e = await setReplyEmail(e);
        }

        setReplyingTo(e);

        setReplySubject(formatEmailSubject(e.subject, caseId, true));

        if (e.attachments.length > 0)
            dispatch(
                setCaseAttachmentsSelected(
                    e.attachments.map((a) => ({
                        name: a.filename,
                        azureFileId: a.azureFileId,
                        size: a.size,
                    })),
                    caseId
                )
            );

        setCaseViewState(caseId, {
            ...c.viewState,
            mode: "forwarding",
            currentTab: 5,
        });
    };

    if (caseId == null || c == null)
        return (
            <React.Fragment>
                <Helmet title="Case not loaded" />
                <Typography variant="h3" gutterBottom display="inline">
                    Case not loaded
                </Typography>
            </React.Fragment>
        );

    const setCurrentTab = (tab) => {
        setCaseViewState(caseId, { ...c.viewState, currentTab: tab });
    };

    const mode = c.viewState.mode;

    const closeReply = () => {
        setCaseViewState(caseId, {
            ...c.viewState,
            mode: caseTabs.normal,
            currentTab: 0,
        });
    };

    const openDocument = async (attachment) => {
        try {
            const previewPath = await documentService.retrieveDocumentPreviewPath({
                reference: attachment.reference,
                contentType: attachment.contentType,
            });

            if (previewPath === null) {
                dispatch(setSnackAction("File is password protected and cannot be previewed. Download from the Case Files tab to view locally.", "warning"));
                return;
            }

            let parsedFileName = attachment.filename.split(".");

            openFileViewer(
                {
                    type: parsedFileName[parsedFileName.length - 1],
                    path: previewPath,
                    name: attachment.filename,
                },
                caseId,
                attachment
            );
        } catch (e) {
            console.error(e);
        }
    };

    const handleCloseMilestoneViewer = () => {
        setMilestoneViewerOpen(false);
        setDocumentGuid(null);
    };

    const handleOpenCaseResearch = async () => {
        if (inCall) {
            dispatch(setSnackAction("Cannot enter research mode whilst on a call", "error"));
            return;
        }

        dispatch(setBackdropOpen(true));
        try {
            await startCaseResearch();
            dispatch(setCaseResearchOpen(true));
        } catch (error) {
            console.error(error);
        }
        dispatch(setBackdropOpen(false));
    };

    const onAddPrecedent = () => {
        setCurrentTab(c.reply.precedents.length + 6);
    };

    return (
        <>
            <DraftEmailsSummaryDialog open={draftEmailSummaryDialogOpen} onClose={() => setDraftEmailSummaryDialogOpen(false)} />
            <Helmet title={`Case Number ${caseId}`} />
            <Paper className={classes.tabPaper}>
                <Tabs value={currentTab} onChange={handleTabChange}>
                    <Tab value={0} label="Details" />
                    <Tab value={1} label="Case Files" />
                    <Tab value={2} label="Draft Files" />
                    <Tab value={3} label="History" />
                    <Tab value={4} style={{ display: c.caseSummary?.isET ? "" : "none" }} label="ET Claim Detail" />
                    <Tab
                        value={5}
                        style={{
                            display: mode === caseTabs.replying || mode === caseTabs.forwarding ? "" : "none",
                        }}
                        label={mode === caseTabs.replying ? "Reply" : "Forward"}
                    />
                    {c.reply.precedents.map((p, index) => (
                        <Tab value={index + 6} key={p.precedentId} label={p.name} />
                    ))}
                </Tabs>
            </Paper>
            <Divider my={6} />
            {c.caseSummary.isContractExpired && !dismissedExpiredContractAlerts.includes(c.caseSummary.caseId) && (
                <Alert
                    onClose={() => setDismissedExpiredContractAlerts((current) => [...current, c.caseSummary.caseId])}
                    className={classes.contractExpiredAlert}
                    severity="warning"
                >
                    {`The contract on this case expired on ${moment(c.caseSummary.contractExpiryDate).format("DD/MM/YYYY [at] HH:mm")}`}
                </Alert>
            )}
            <TabPanel value={currentTab} index={0}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={3}>
                        <CaseDetailsCard />
                        {
                            c.caseSummary.isCappedHours &&
                            <CaseCappedContractCard
                                contractId={c.caseSummary.contractId}
                            />
                        }
                        <CaseDescriptionCard
                            setSnackAction={setSnackAction}
                            setCaseDescription={setCaseDescription}
                        />
                        <EmployeeCard />
                        <ContactsCard setReplyContacts={setReplyContacts} />
                        <ExternalContactsCard />
                        <LawyerCard />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <WorkflowCard />
                        {!c.caseSummary.dateClosed && (
                            <Box display="flex" my={3}>
                                <Button onClick={newDraftEmail} color="primary" data-cy="new_email">
                                    <Typography variant="subtitle2">New Email</Typography>
                                </Button>
                                {draftEmailsSummary.data?.data && draftEmailsSummary.data.data.length > 0 && <EmailDrafts />}
                                {!callNoteOpen && (
                                    <Button onClick={() => setCallNoteOpen(true, caseId)} color="primary" data-cy="new_call">
                                        New Call
                                    </Button>
                                )}
                                {!internalNoteOpen && (
                                    <Button onClick={() => setInternalNoteOpen(true, caseId)} color="primary" data-cy="new_note">
                                        New Internal Note
                                    </Button>
                                )}
                                {!emailOpen && (
                                    <Button onClick={() => setEmailOpen(true, caseId)} color="primary" data-cy="log_email">
                                        Log Email
                                    </Button>
                                )}
                                <Button onClick={handleOpenCaseResearch} color="primary" data-cy="research">
                                    Research
                                </Button>
                                {!miscActivityOpen && (
                                    <Button onClick={() => setMiscActivityOpen(true, caseId)} color="primary" data-cy="new_misc_activity">
                                        New Misc Activity
                                    </Button>
                                )}
                            </Box>
                        )}
                        <Collapse in={callNoteOpen || internalNoteOpen || emailOpen || miscActivityOpen}>
                            {callNoteOpen ? (
                                <CallCard
                                    userId={userState.userProfile.userId}
                                    setCallNoteText={setCallNoteText}
                                    setCallNoteOpen={setCallNoteOpen}
                                    setCallNoteCaseActivityId={setCallNoteCaseActivityId}
                                    setCallNoteIssueFocus={setCallNoteIssueFocus}
                                    setCallNoteAdviceFocus={setCallNoteAdviceFocus}
                                    setCallNoteActionFocus={setCallNoteActionFocus}
                                    setCallNoteMode={setCallNoteMode}
                                />
                            ) : internalNoteOpen ? (
                                <InternalNoteCard
                                    setInternalNoteOpen={setInternalNoteOpen}
                                    setInternalNoteText={setInternalNoteText}
                                    setInternalNoteCaseActivityId={setInternalNoteCaseActivityId}
                                />
                            ) : emailOpen ? (
                                <EmailCard
                                    setEmailOpen={setEmailOpen}
                                    setFullScreenEmail={setFullScreenEmail}
                                    addCaseEvent={addCaseEvent}
                                    incrementEventCount={incrementEventCount}
                                    setCaseActivityIds={setCaseActivityIds}
                                />
                            ) : miscActivityOpen ? (
                                <MiscActivityCard />
                            ) : (
                                <div></div>
                            )}
                        </Collapse>
                        <CaseHistoryCard
                            openDocument={openDocument}
                            onReply={caseHistoryReply}
                            onForward={caseHistoryForward}
                            setCallNoteText={setCallNoteText}
                            setCallNoteOpen={setCallNoteOpen}
                            setCallNoteCaseActivityId={setCallNoteCaseActivityId}
                            setInternalNoteText={setInternalNoteText}
                            setInternalNoteOpen={setInternalNoteOpen}
                            setInternalNoteCaseActivityId={setInternalNoteCaseActivityId}
                            setCallNoteMode={setCallNoteMode}
                            setDocumentGuid={setDocumentGuid}
                            setSnack={setSnackAction}
                            replyLoading={replyLoading}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <CasePermissionCard />
                        <TimeCard />
                        <OrganisationCard />
                        <CaseActionsCard caseId={caseId} />
                        <MilestoneDocumentsCard setMilestoneViewerOpen={setMilestoneViewerOpen} setDocumentGuid={setDocumentGuid} documentGuid={documentGuid} />
                        <EvidenceCard files={c.evidenceFiles} openFileViewer={openFileViewer} />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={currentTab} index={1} className={classes.filePanel}>
                {isCaseFilesEnabled ?
                    <Files caseId={caseId} setMilestoneViewerOpen={setMilestoneViewerOpen} setDocumentGuid={setDocumentGuid} />
                    :
                    <CaseFiles caseId={caseId} setMilestoneViewerOpen={setMilestoneViewerOpen} setDocumentGuid={setDocumentGuid} />
                 }
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                <DraftFiles caseId={caseId} />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
                <CaseHistory
                    caseId={caseId}
                    openDocument={openDocument}
                    onReply={caseHistoryReply}
                    onForward={caseHistoryForward}
                    replyLoading={replyLoading}
                />
            </TabPanel>
            <TabPanel value={currentTab} index={4}>
                <ETClaimDetails />
            </TabPanel>
            <CaseReplyAndPrecedentTabs currentTab={currentTab} onCloseReply={closeReply} onAddPrecedent={onAddPrecedent} />
            <DocumentDialog open={!!documentGuid} onClose={handleCloseMilestoneViewer} documentGuid={documentGuid} />
            <CaseFilesTreeChangeDialog
                openTabChangeConfirmDialog={openTabChangeConfirmDialog}
                handleSkipTabChange={handleSkipTabChange}
                handleConfirmTabChange={handleConfirmTabChange}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    caseState: state.caseReducer,
    userState: state.userReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setReplyingTo: (replyingTo) => dispatch(setReplyingToAction(replyingTo)),
        setReplyEditorHtml: (caseId, editorValue) => dispatch(setReplyEditorHtmlAction(caseId, editorValue)),
        setReplyContacts: (caseId, contacts) => dispatch(setReplyContactsAction(caseId, contacts)),
        setReplyNonContacts: (caseId, nonContacts) => dispatch(setReplyNonContactsAction(caseId, nonContacts)),
        setReplyIntCcs: (caseId, userIds) => dispatch(setReplyIntCcsAction(caseId, userIds)),
        setInboundEmailNonContacts: (caseId, emails) => dispatch(setInboundEmailNonContactsAction(caseId, emails)),
        setInboundEmailContacts: (caseId, contacts) => dispatch(setInboundEmailContactsAction(caseId, contacts)),
        setCaseViewState: (caseId, viewState) => dispatch(setCaseViewStateAction(caseId, viewState)),
        setCaseWindowStatus: (status) => dispatch(setCaseWindowStatus(status)),
        setSnackAction: (message, severity) => dispatch(setSnackAction(message, severity)),
        setUserTimerRunning: (isRunning) => dispatch(setUserTimerRunning(isRunning)),
        addCaseEvent: (caseId, event) => dispatch(addCaseEventAction(caseId, event)),
        setCallNoteIssueFocus: (isFocus) => dispatch(setCallNoteIssueFocus(isFocus)),
        setCallNoteAdviceFocus: (isFocus) => dispatch(setCallNoteAdviceFocus(isFocus)),
        setCallNoteActionFocus: (isFocus) => dispatch(setCallNoteActionFocus(isFocus)),
        openFileViewer: (file, caseId, attachment) => dispatch(openFileViewer(file, caseId, attachment)),
        setCallNoteOpen: (isOpen, caseId) => dispatch(setCallNoteOpen(isOpen, caseId)),
        setReplySubject: (subject) => dispatch(setReplySubjectAction(subject)),
        setReplyPrecedents: (precedents) => dispatch(setReplyPrecedentsAction(precedents)),
        setMilestoneViewerOpen: (isOpen) => dispatch(setMilestoneViewerOpen(isOpen)),
        setInternalNoteOpen: (isOpen, caseId) => dispatch(setInternalNoteOpen(isOpen, caseId)),
        setCaseDescription: (description) => dispatch(setCaseDescription(description)),
        setCallNoteText: (text, caseId) => dispatch(setCallNoteText(text, caseId)),
        setCallNoteCaseActivityId: (id, caseId) => dispatch(setCallNoteCaseActivityId(id, caseId)),
        setInternalNoteText: (text, caseId) => dispatch(setInternalNoteText(text, caseId)),
        setInternalNoteCaseActivityId: (id, caseId) => dispatch(setInternalNoteCaseActivityId(id, caseId)),
        setCallNoteMode: (mode, caseId) => dispatch(setCallNoteMode(mode, caseId)),
        setEmailOpen: (isOpen, caseId) => dispatch(setEmailOpen(isOpen, caseId)),
        setFullScreenEmail: (email) => dispatch(setFullScreenEmail(email)),
        incrementEventCount: (caseId) => dispatch(incrementEventCount(caseId)),
        setCaseActivityIds: (caseActivityIds, caseId) => dispatch(setCaseActivityIds(caseActivityIds, caseId)),
        setMiscActivityOpen: (isOpen, caseId) => dispatch(setMiscActivityOpen(isOpen, caseId)),
        setCaseAttachmentsSelected: (attachments, caseId) => dispatch(setCaseAttachmentsSelected(attachments, caseId)),
        setDraftAttachmentsSelected: (attachments, caseId) => dispatch(setDraftAttachmentsSelected(attachments, caseId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Case);
