import axios from "../plugins/axios";
import { converterUrls } from "../constants/documentConstants";
import axios_raw from "axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const retrieveDocumentPreviewPath = async ({ reference, contentType, isForFilePreview = true, filename = null }) => {
    const isVideoContent = contentType.startsWith("video");
    const endpointUrl = isVideoContent
        ? "document/retrievevideo"
        : "document/retrieve";

    try {
        const response = await axios.get(
            `${apiUrl}/${endpointUrl}/${reference}?isForFilePreview=${isForFilePreview}&filename=${filename}`,
            {
                responseType: isVideoContent ? "stream" : "arraybuffer",
            }
        );
        
        if (response.status === 204)
            return null;
        
        if (isVideoContent) 
            return response.data;

        const blob = new Blob([response.data], { type: contentType });
        return window.URL.createObjectURL(blob);
    } catch (e) {
        throw new Error(e?.message || "Could not fetch document.");
    }
};

const openDocument = async (attachmentReference, isForFilePreview = true) => {
    try {
        const response = await axios.get(
            `${apiUrl}/document/retrieve/${attachmentReference}?isForFilePreview=${isForFilePreview}`,
            { responseType: "arraybuffer" }
        );
        
        if (response.status === 204)
            return null;
        
        return response.data;
    } catch (error) {
        throw error;
    }
};

const exportDocument = async ({ html, css, options, converterFileType }) => {
    return await axios_raw.post(
        converterUrls[converterFileType],
        {
            html,
            css,
            options,
        },
        { responseType: "blob" }
    );
};

const openInSharepoint = ({ blob, caseId, fileName }) => {
    let formData = new FormData();
    let file = new File([blob], fileName);
    formData.append("files", file);
    formData.append("caseId", caseId);

    return axios.post(
        `${apiUrl}/sharepoint/CreateSharepointDocumentFromPrecedent`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );
};

const documentService = {
    retrieveDocumentPreviewPath,
    openDocument,
    exportDocument,
    openInSharepoint,
};

export default documentService;
