import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import {
    Autocomplete,
} from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@material-ui/icons';
import { setSnackAction } from "../redux/actions/snackActions";
import { makeStyles } from "@material-ui/core/styles";
import useCoreServices from "../hooks/queries/useCoreServices";
import useServiceTypes from "../hooks/queries/useServiceTypes";
import InvoiceSearchTable from "../components/InvoiceSearchTable";
import InvoiceSearchDialog from "../components/dialogs/InvoiceSearchDialog";
import InvoiceSearchConfirmationDialog from "../components/dialogs/InvoiceSearchConfirmationDialog";
import useAddInvoiceSearch from "../hooks/mutations/useAddInvoiceSearch";
import useTimesheetByActivity from '../hooks/queries/useTimesheetByActivity';
import useTimesheetCSVExport from '../hooks/useTimesheetCSVExport';

const useStyles = makeStyles((theme) => ({
    heading: {
        color: theme.palette.primary.main,
        margin: theme.spacing(6, 0),
    },
    paper: {
        padding: theme.spacing(6),
        marginBottom: theme.spacing(3),
    },
    rowSpacing: {
        marginBottom: theme.spacing(1),
    },
    fullWidthInput: {
        width: "100%",
    },
    autoCompleteOption: {
        marginRight: 8,
    },
    floatingLabel: {
        color:  theme.palette.text.primary,
    },
    selectedChip:{
        backgroundColor: "#F4F2FE",
        marginRight: theme.spacing(1),
    },
}));

const renderServiceOption = (option, { selected }) => (
    <>
        <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={selected}
        />
        {option.name}
    </>
);

const renderCoreServicesInput = (params, classes, isFormInvalid) => {
    return (
        <TextField
            {...params}
            variant="outlined"
            label="Core Service"
            placeholder="Core Service..."
            InputLabelProps={{
                className: classes.floatingLabel,
            }}
            error={isFormInvalid}
            helperText={isFormInvalid && "Please select a core service"}
        />
    );
};

const renderServiceTypesInput = (params) => {
    return (
        <TextField
            {...params}
            variant="outlined"
            label="Service Type"
            placeholder="Service Type..."
        />
    );
};

const InvoiceTool = () => {

    // State stuff
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentDate = new Date();
    const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const lastDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

    const [fromDate, setFromDate] = useState(firstDayOfPreviousMonth);
    const [toDate, setToDate] = useState(lastDayOfPreviousMonth);
    const [selectedCoreServices, setSelectedCoreServices] = useState([]);
    const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
    const [isFormInvalid, setIsFormInvalid] = useState(false);
    const [isInvoiceSearchDialogOpen, setIsInvoiceSearchDialogOpen] = useState(false);
    const [isInvoiceSearchConfirmationDialogOpen, setIsInvoiceSearchConfirmationDialogOpen] = useState(false);    
    const [isCSVQueryEnabled, setIsCSVQueryEnabled] = useState(false);

    // Queries / hooks
    const {
        data: coreServices,
        isLoading: isCoreServicesLoading,
        isError: isCoreServicesError,
        error: coreServicesError,
    } = useCoreServices();

    const {
        data: serviceTypes,
        isLoading: isServiceTypesLoading,
        isError: isServiceTypesError,
        error: serviceTypesError,
    } = useServiceTypes({ coreServices: selectedCoreServices });

    const {
        mutate: addInvoiceSearch,
        isLoading: isAddInvoiceSearchLoading,
    } = useAddInvoiceSearch();

    const { CsvLinkComponent, exportToCsv } = useTimesheetCSVExport();

    const {
        data: csvTimesheetData,
        isLoading: isCsvTimesheetDataLoading,
        isError: isCsvTimesheetDataError,
        error: csvTimesheetDataError,
        isSuccess: isCsvTimesheetDataSuccess,
    } = useTimesheetByActivity({
        fromDate,
        toDate,
        coreServices: selectedCoreServices,
        serviceTypes: selectedServiceTypes,
        isChargeable: true,
        excludeInvoiced: true,
        enabled: isCSVQueryEnabled,
        onSuccess: (data) => {
            exportToCsv(data);
            setIsCSVQueryEnabled(false);
        },
    });

    const isValid = useMemo(() => {
        return  () => {
            let isInvalid = false;
            if (selectedCoreServices?.length == 0){
                isInvalid = true;
            }
            setIsFormInvalid(isInvalid);
            return !isInvalid;
        }
    })

    const handleCoreServiceChange = (event, values) => {
        setIsFormInvalid(false);
        setSelectedCoreServices(values);
    }

    const handleServiceTypeChange = (event, values) => {
        setSelectedServiceTypes(values);
    }

    const handleGetActivitiesClick = () => {
        if (isValid()) {
            setIsInvoiceSearchDialogOpen(true);
        }
    };

    // First InvoiceSearch Dialog
    const handleSetForInvoicingClick = () => {
        setIsInvoiceSearchDialogOpen(false);
        setIsInvoiceSearchConfirmationDialogOpen(true);
    }

    const handleInvoiceSearchDialogClose = () => {
        setIsInvoiceSearchDialogOpen(false);
    }

    const handleDownloadCSVClick = () => {
        setIsCSVQueryEnabled(true);
        setIsInvoiceSearchDialogOpen(false);
    }

    // Second InvoiceSearch Dialog
    const handleInvoiceSearchConfirmationDialogClose = () => {
        setIsInvoiceSearchConfirmationDialogOpen(false);
    }

    const handleInvoiceSearchConfirmationDialogConfirm = () => {
        addInvoiceSearch({
            startDate: fromDate,
            endDate: toDate,
            coreServices: selectedCoreServices,
            serviceTypes: selectedServiceTypes,
        });
        setIsInvoiceSearchConfirmationDialogOpen(false);
    }

    return (
        <>
            <Typography
                variant="h3"
                className={classes.heading}
            >
                Invoice Tool
            </Typography>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        sm={10}
                        md={8}
                        lg={6}
                    >
                        <Paper
                            className={classes.paper}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className={classes.rowSpacing}
                                >
                                    <KeyboardDatePicker
                                        className={classes.fullWidthInput}
                                        format="dd/MM/yyyy"
                                        inputVariant="outlined"
                                        label="From Date"
                                        value={fromDate}
                                        onChange={(date) => setFromDate(date)}
                                        maxDate={toDate || new Date()}
                                    >
                                    </KeyboardDatePicker>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    className={classes.rowSpacing}
                                >
                                    <KeyboardDatePicker
                                        className={classes.fullWidthInput}
                                        format="dd/MM/yyyy"
                                        inputVariant="outlined"
                                        value={toDate}
                                        onChange={(date) => setToDate(date)}
                                        label="To Date"
                                        minDate={fromDate}
                                        maxDate={new Date()}
                                    >
                                    </KeyboardDatePicker>
                                </Grid>
                                <Grid item xs={12} className={classes.rowSpacing}>
                                    { isCoreServicesLoading && <CircularProgress /> }
                                    { isCoreServicesError && (
                                        <Typography>
                                            { coreServicesError?.message || "Failed to load core services"  }
                                        </Typography>
                                    )}
                                    { coreServices &&  (
                                        <Autocomplete
                                            multiple
                                            id="coreservices-multi-select"
                                            options={coreServices}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.name}
                                            onChange={handleCoreServiceChange}
                                            value={selectedCoreServices}
                                            renderOption={renderServiceOption}
                                            renderInput={(params) => renderCoreServicesInput(params, classes, isFormInvalid)}
                                            ChipProps={{
                                                className: classes.selectedChip,
                                            }}

                                        />
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.rowSpacing}
                                >
                                    { isServiceTypesLoading && <CircularProgress /> }
                                    { isServiceTypesError && (
                                        <Typography>
                                            { serviceTypesError?.message || "Failed to load services types"  }
                                        </Typography>
                                    )}
                                    <Autocomplete
                                        multiple
                                        id="servicetypes-multi-select"
                                        options={serviceTypes || []}
                                        disabled={selectedCoreServices?.length === 0}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={handleServiceTypeChange}
                                        value={selectedServiceTypes}
                                        getOptionSelected={(option, value) => option.name === value.name }
                                        renderOption={renderServiceOption}
                                        renderInput={renderServiceTypesInput}
                                        ChipProps={{
                                            className: classes.selectedChip,
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.rowSpacing}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="end"
                                    >
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleGetActivitiesClick}
                                        >
                                            Get Activities
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Card>
                            <CardContent>
                                <InvoiceSearchTable />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <InvoiceSearchDialog 
                open={isInvoiceSearchDialogOpen}
                fromDate={fromDate}
                toDate={toDate}
                selectedCoreServices={selectedCoreServices}
                selectedServiceTypes={selectedServiceTypes}
                onClose={handleInvoiceSearchDialogClose}
                onSetForInvoicingClick={handleSetForInvoicingClick}
                onDownloadCsvClick={handleDownloadCSVClick}
            />
            <InvoiceSearchConfirmationDialog 
                open={isInvoiceSearchConfirmationDialogOpen}
                onClose={handleInvoiceSearchConfirmationDialogClose}
                onConfirmation={handleInvoiceSearchConfirmationDialogConfirm}
                isLoading={isAddInvoiceSearchLoading}
            />
            <CsvLinkComponent />
        </>
    )
}

export default InvoiceTool;