import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import {
    Avatar,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    makeStyles,
    MenuItem,
    TextField,
    Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import useUserAutomaticEmailReply from "../hooks/queries/useUserAutomaticEmailReply";
import MyCKEditor from "../ckeditor/MyCKEditor";
import useTeamUsersForManager from "../hooks/queries/useTeamUsersForManager";
import { useAuth } from "../contexts/authContext";
import userRoles from "../constants/userRoles";
import avatarStyles from "../theme/avatars";
import {setMinutes, addDays, isValid} from "date-fns";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import useUpdateUserAutomaticEmailReply from "../hooks/mutations/useUpdateUserAutomaticEmailReply";
import { useHistory } from "react-router-dom";
import { setSnackAction } from "../redux/actions/snackActions";
import { Alert } from "@material-ui/lab";
import { red } from "@material-ui/core/colors";
import Voicemail from "../components/automaticReplies/Voicemail";
import useUpdateVoicemailForUser from "../hooks/mutations/useUpdateVoicemailForUser";
import useVoicemailForUser from "../hooks/queries/useVoicemailForUser";
import useFilteredAdvisers from "../hooks/useFilteredAdvisers";
import {getDstCorrectedDateString} from "../helpers/dateHelpers";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(3),
    },
    menuItem: {
        display: "flex",
        alignItems: "center",
    },
    avatar: {
        marginRight: theme.spacing(2),
    },
    datePicker: {
        margin: theme.spacing(3, 0),
    },
    subSection: {
        marginLeft: theme.spacing(6),
    },
    formButtons: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        margin: theme.spacing(6, 0),
    },
    saveButton: {
        marginLeft: theme.spacing(2),
    },
    editorError: {
        color: "red",
        marginLeft: theme.spacing(3),
    },
    datePickerCheckbox: {
        margin: theme.spacing(3, 0),
    },
    loadingContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    discardButton: {
        color: red[900],
    },
    mainCheckbox: {
        margin: theme.spacing(6, 0),
    },
}));

const errorStrings = {
    INVALID: "dd/mm/yyyy, hh:mm",
    TO_DATE_PAST: "End time must be in the future",
    TO_BEFORE_FROM: "Start time must be before end time",
    EDITOR_EMPTY: "Email content cannot be blank",
};

const defaultFromDate = setMinutes(new Date(), 0);
const defaultToDate = addDays(setMinutes(new Date(), 0), 1);

const AutomaticReplies = () => {
    const classes = useStyles();
    const avatarClasses = avatarStyles();
    const { userProfile } = useSelector((state) => state.userReducer);
    const [editor, setEditor] = useState();
    const [editorContent, setEditorContent] = useState("");
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState("");
    const [tempUserId, setTempUserId] = useState(null);
    const [emailReplyActive, setEmailReplyActive] = useState(false);
    const [emailReplyHoursActive, setEmailReplyHoursActive] = useState(false);
    const [voicemailActive, setVoicemailActive] = useState(false);
    const [fromDate, setFromDate] = useState(defaultFromDate);
    const [toDate, setToDate] = useState(defaultToDate);
    const [fromDateError, setFromDateError] = useState("");
    const [toDateError, setToDateError] = useState("");
    const [editorError, setEditorError] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [recordingBlob, setRecordingBlob] = useState(null);
    const { hasRole } = useAuth();
    const reply = useUserAutomaticEmailReply({ userId });
    const teamUserIds = useTeamUsersForManager({ managerId: userId });
    const updateReply = useUpdateUserAutomaticEmailReply();
    const updateVoicemail = useUpdateVoicemailForUser();
    const userVoicemail = useVoicemailForUser({
        userId,
        onSuccess: (data) => setVoicemailActive(data.isActive || false),
    });
    const history = useHistory();
    const dispatch = useDispatch();
    const filteredAdvisers = useFilteredAdvisers();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isFormValid()) return;
        updateReply.mutate(
            {
                userId,
                content: editor.getData(),
                fromDate: emailReplyHoursActive ? getDstCorrectedDateString(fromDate) : null,
                toDate: emailReplyHoursActive ? getDstCorrectedDateString(toDate) : null,
                isActive: emailReplyActive,
            },
            {
                onError: (e) =>
                    dispatch(
                        setSnackAction(
                            e?.message ||
                                "Could not save automatic email reply settings",
                            "error"
                        )
                    ),
                onSuccess: () => {
                    let file = null;
                    const formData = new FormData();
                    formData.append("isActive", voicemailActive);
                    if (recordingBlob) {
                        file = new File([recordingBlob], "temp.wav", {
                            type: "audio/wav",
                        });
                        formData.append("file", file);
                    }
                    updateVoicemail.mutate(
                        { formData, userId },
                        {
                            onError: (e) =>
                                dispatch(
                                    setSnackAction(
                                        e?.message ||
                                            "Could not save voicemail settings",
                                        "error"
                                    )
                                ),
                            onSuccess: () => {
                                dispatch(
                                    setSnackAction(
                                        "Saved automatic replies",
                                        "success"
                                    )
                                );
                                history.push("/");
                            },
                        }
                    );
                },
            }
        );
    };

    const isFormValid = () => {
        if (!emailReplyActive) return true;
        return (
            !!editor?.getData()?.length &&
            (!emailReplyHoursActive ||
                (!fromDateError.length && !toDateError.length))
        );
    };

    const setEditorHtml = (html) => {
        if (!editor) return;
        setEditorContent(html);
        editor.setData(html);
    };

    const handleChangeUserId = (id) => {
        setTempUserId(id);
        setDialogOpen(true);
    };

    const handleConfirmDiscard = () => {
        if (tempUserId) {
            setUserId(tempUserId);
            setTempUserId(null);
            setDialogOpen(false);
            return;
        }
        history.push("/");
    };

    useEffect(() => {
        if (reply?.data) {
            if (editor != null) editor.setData(reply.data.content);
            setEditorContent(reply.data.content);
            setEmailReplyActive(reply.data.isActive);

            if (reply.data.fromDate && reply.data.toDate) {
                setToDate(new Date(reply.data.toDate));
                setFromDate(new Date(reply.data.fromDate));
                setEmailReplyHoursActive(true);
            } else {
                setToDate(defaultToDate);
                setFromDate(defaultFromDate);
                setEmailReplyHoursActive(false);
            }
        }
    }, [reply?.data, editor]);

    useEffect(() => {
        if (!userProfile?.userId || !userId) return;

        if (hasRole(userRoles.SUPER_USER) || hasRole(userRoles.LEGAL_ADMIN)) {
            setUsers(filteredAdvisers);
            return;
        }

        if (teamUserIds?.data?.length) {
            setUsers(
                filteredAdvisers.filter((x) =>
                    [userProfile.userId, userId, ...teamUserIds.data].includes(
                        x.userId
                    )
                )
            );
        }
    }, [filteredAdvisers, teamUserIds?.data, userProfile?.userId, userId]);

    useEffect(() => {
        if (userProfile?.userId) setUserId(userProfile.userId);
    }, [userProfile?.userId]);

    useEffect(() => {
        // TODO: Replace this gross logic with minDateTime and maxDateTime when we upgrade to mui v5

        if (!emailReplyHoursActive || !emailReplyActive) {
            setFromDateError("");
            setToDateError("");
            return;
        }

        if (!isValid(fromDate) || !isValid(toDate)) {
            if (!isValid(fromDate)) setFromDateError(errorStrings.INVALID);

            if (!isValid(toDate)) setToDateError(errorStrings.INVALID);
        } else if (isValid(toDate) && toDate < new Date()) {
            setToDateError(errorStrings.TO_DATE_PAST);
            if (isValid(fromDate)) setFromDateError("");
        } else if (isValid(toDate) && isValid(fromDate) && toDate < fromDate) {
            setToDateError("");
            setFromDateError(errorStrings.TO_BEFORE_FROM);
        } else {
            setToDateError("");
            setFromDateError("");
        }
    }, [fromDate, toDate, emailReplyHoursActive, emailReplyActive]);

    useEffect(() => {
        if (emailReplyActive && !editorContent.length) {
            setEditorError(errorStrings.EDITOR_EMPTY);
            return;
        }
        setEditorError("");
    }, [emailReplyActive, editorContent]);
    

    return (
        <div>
            <Helmet title="Automatic Replies" />
            <div className={classes.header}>
                <Typography variant="h3" gutterBottom display="inline">
                    Automatic Replies
                </Typography>
                {users.length > 0 && (
                    <TextField
                        select
                        label="User"
                        value={userId}
                        onChange={(e) => handleChangeUserId(e.target.value)}
                        variant="outlined"
                    >
                        {users.map((user) => (
                            <MenuItem key={user.userId} value={user.userId}>
                                <div className={classes.menuItem}>
                                    <Avatar
                                        className={`${classes.avatar} ${avatarClasses.small}`}
                                        alt={user.name}
                                        src={user.photo}
                                    />
                                    {user.name}
                                </div>
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            </div>
            <div className={classes.loadingContainer}>
                {reply.isLoading && <CircularProgress />}
                {reply.isError && (
                    <Alert variant="outlined" severity="error">
                        {reply.error}
                    </Alert>
                )}
            </div>
            {reply.isSuccess && (
                <form onSubmit={handleSubmit}>
                    <FormGroup className={classes.mainCheckbox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={emailReplyActive}
                                    onChange={(e) =>
                                        setEmailReplyActive(e.target.checked)
                                    }
                                />
                            }
                            label={<Typography variant="h5">Email</Typography>}
                        />
                    </FormGroup>
                    <div className={classes.subSection}>
                        <MyCKEditor
                            setEditor={setEditor}
                            onChange={setEditorHtml}
                            disabled={!emailReplyActive}
                        />
                        <FormHelperText className={classes.editorError}>
                            {editorError}
                        </FormHelperText>
                        <FormGroup className={classes.datePickerCheckbox}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={emailReplyHoursActive}
                                        onChange={(e) =>
                                            setEmailReplyHoursActive(
                                                e.target.checked
                                            )
                                        }
                                        disabled={!emailReplyActive}
                                    />
                                }
                                label="Send replies only during this time period:"
                            />
                        </FormGroup>
                        <div className={classes.datePicker}>
                            <KeyboardDateTimePicker
                                value={fromDate}
                                format="dd/MM/yyyy, HH:mm"
                                inputVariant="outlined"
                                onChange={(date) => setFromDate(date)}
                                disabled={
                                    !emailReplyActive || !emailReplyHoursActive
                                }
                                label="Start Time"
                                helperText={fromDateError}
                                error={!!fromDateError.length}
                            />
                        </div>
                        <div className={classes.datePicker}>
                            <KeyboardDateTimePicker
                                value={toDate}
                                format="dd/MM/yyyy, HH:mm"
                                inputVariant="outlined"
                                onChange={(date) => setToDate(date)}
                                disabled={
                                    !emailReplyActive || !emailReplyHoursActive
                                }
                                label="End Time"
                                helperText={toDateError}
                                error={!!toDateError.length}
                            />
                        </div>
                    </div>
                    <Divider />
                    <FormGroup className={classes.mainCheckbox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={voicemailActive}
                                    onChange={(e) =>
                                        setVoicemailActive(e.target.checked)
                                    }
                                />
                            }
                            label={
                                <Typography variant="h5">Voicemail</Typography>
                            }
                        />
                    </FormGroup>
                    <div className={classes.subSection}>
                        <Voicemail
                            userId={userId}
                            setRecordingBlobToSave={setRecordingBlob}
                            recordingBlobToSave={recordingBlob}
                            disabled={!voicemailActive}
                            userVoicemail={userVoicemail}
                        />
                    </div>
                    <Divider />
                    <div className={classes.formButtons}>
                        {updateReply.isLoading || updateVoicemail.isLoading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => setDialogOpen(true)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={classes.saveButton}
                                    color="secondary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </div>
                </form>
            )}
            <Dialog open={dialogOpen}>
                <DialogTitle>Discard Changes?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Any changes you have made will be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialogOpen(false)}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmDiscard}
                        className={classes.discardButton}
                    >
                        Discard
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AutomaticReplies;
