import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const parseEmail = async ({ fromAddress, htmlBody }) => {
    try {
        const response = await axios.post(`${apiUrl}/parseEmailContent`, {
            from_address: fromAddress,
            htmlBody,
        });
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not parse email content");
    }
};

const parseEmailService = {
    parseEmail,
};

export default parseEmailService;
