import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const setCaseType = async ({ caseId, currentStageId, caseTypeId }) => 
    await axios.post(`${apiUrl}/setCaseType`, {
        caseId,
        caseTypeId,
        currentStageId,
    })

export { setCaseType };