import axios, { setAuthHeader } from "../plugins/axios";

const microsoftWorkNestTokenExchange = async (authToken) => {
  setAuthHeader(authToken.idToken);

  return await axios.get(
    `${process.env.REACT_APP_AUTH_API_URL}/token/microsoft`
  );
};

const tokenService = {
  microsoftWorkNestTokenExchange,
};

export default tokenService;
