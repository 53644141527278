import * as types from '../constants';
import axios from "../../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const initialState = {
    categories: [],
    articles: [],
    documents: [],
    currentArticle: {}
};

export const actionCreators = {
    getInitialData: () => async (dispatch, getState) => {
        let response = await axios.get(`${apiUrl}/knowledge/categories`);
        dispatch({ type: types.SET_KNOWLEDGE_CATEGORIES, categories: response.data });

        response = await axios.get(`${apiUrl}/knowledge/articles`);
        dispatch({ type: types.SET_KNOWLEDGE_ARTICLES, articles: response.data });

        response = await axios.get(`${apiUrl}/knowledge/documents`);
        dispatch({ type: types.SET_KNOWLEDGE_DOCUMENTS, documents: response.data });
    },

    updateArticle: (article, field) => async (dispatch, getState) => {
        let response = await axios.post(`${apiUrl}/knowledge/updatearticle`, { id: article.id, [field]: article[field] });
        dispatch({ type: types.UPDATE_ARTICLE, article });
    }
};

const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {

        case (types.SET_KNOWLEDGE_CATEGORIES): {
            return {
                ...state,
                categories: action.categories
            };
        }

        case (types.SET_KNOWLEDGE_ARTICLES): {
            return {
                ...state,
                articles: action.articles
            };
        }

        case (types.SET_KNOWLEDGE_DOCUMENTS): {
            return {
                ...state,
                documents: action.documents
            };
        }

        case (types.UPDATE_ARTICLE): {
            let article = action.article;
            return {
                ...state,
                articles: state.articles.map(x => x.id === article.id ? article : x)
            };
        }
        

        default:
            return state;
    }
};

export default reducer;