import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const editExistingAccount = (account) =>
    axios.post(`${apiUrl}/editExistingAccount/`, account);

const reEnablePartnerMember = (account) =>
    axios.post(`${apiUrl}/updatePartnerMemberAccountStatus/`, account);

const getAccountContacts = (accountId) =>
    axios.get(`${apiUrl}/getaccountcontacts/${accountId}`);

const fetchAccountFiles = async (accountId, fileType, documentType) => {
    try {
        const response = await axios.get(
            `${apiUrl}/getaccountfiles/${accountId}/${fileType}/${documentType}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch documents.");
    }
};

const deleteAccountFile = async (type, fileName) => {
    try {
        await axios.post(
            `${apiUrl}/deletedocument/${type}/?fileName=${fileName}`
        );
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not delete Account File.");
    }
};

const uploadAccountFile = async (
    formData,
    accountId,
    fileType,
    documentType
) => {
    try {
        const response = await axios.post(
            `${apiUrl}/addDocument/${documentType}/${fileType}?accountId=${accountId}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAccountContractSummary = async ({
    accountId,
    pageSize,
    pageNum,
    includeExpired,
}) => {
    try {
        const response = await axios.get(
            `${apiUrl}/getaccountcontractsummary/${accountId}?pageSize=${pageSize}&pageNum=${pageNum}&includeExpired=${includeExpired}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not get Account Contract summary."
        );
    }
};

const getAccountLeiDetails = async ({
    accountId,
    pageSize,
    pageNum,
    includeExpired,
}) => {
    try {
        const response = await axios.get(
            `${apiUrl}/lei/account/${accountId}?pageSize=${pageSize}&pageNum=${pageNum}&includeExpired=${includeExpired}`
        );
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not get Account Lei details."
        );
    }
};
const getAccountsSlim = async (query, pageParam, pageSize) => {
    try {
        const response = await axios.get(
            `${apiUrl}/accounts/slim?query=${query}&pageNum=${pageParam}&pageSize=${pageSize}`
        );
        return response.data;
    }
    catch(e) {
        console.error(e);
        return Promise.reject(
            e?.message || "Could not get accounts"
        )
    }
}

const accountService = {
    editExistingAccount,
    reEnablePartnerMember,
    getAccountContacts,
    fetchAccountFiles,
    uploadAccountFile,
    deleteAccountFile,
    getAccountContractSummary,
    getAccountLeiDetails,
    getAccountsSlim,
};

export default accountService;
