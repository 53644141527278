import axios from "../plugins/axios";
import store from "../redux/store";

import {
    addCaseEventAction,
    updateRetrospectiveMiscActivity,
    incrementEventCount,
    setAdjustCaseMinutes,
    updateCaseChargeableTime,
    updateCurrentMiscActivity,
    resetMiscActivity,
} from "../redux/actions/caseActions";
import { setSnackAction } from "../redux/actions/snackActions";

import {
    getDstCorrectedDateString,
    getStartAndEndDate,
} from "../helpers/dateHelpers";
import { miscActivityGroups } from "../constants/miscActivityGroupConstants";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const sendActivities = async (callId = null) => {
    const { caseTimerReducer, userReducer } = store.getState();
    const { currentActivity, activities } = caseTimerReducer;
    const { userId } = userReducer.userProfile;
    if (!currentActivity && activities.length === 0) return;
    try {
        const currentActivities = currentActivity
            ? [
                  ...activities,
                  {
                      ...currentActivity,
                      finish: new Date(),
                  },
              ]
            : activities;

        let queryString = `${apiUrl}/updatecasetimeevents/${userId}`;
        if (callId != null) queryString += `?callId=${callId}`;

        const response = await axios.post(queryString, currentActivities);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const saveRetrospectiveMiscActivity = async (unallocatedCaseTimeEventIds, caseId) => {

    const { caseReducer, userReducer } = store.getState();
    const dispatch = store.dispatch;
    const { cases } = caseReducer;

    const { userProfile, users } = userReducer;
    const { userId } = userProfile;    

    const c = cases[caseId];
    const activity = c.miscActivity.retrospective;
    const contractId = c.caseSummary.contractId;

    const { startDate, endDate } = getStartAndEndDate(
        activity.date,
        activity.startTime,
        activity.hours,
        activity.minutes
    );

    let formData = new FormData();
    formData.append("stringifiedFiles", JSON.stringify(c.miscActivity?.attachments?.map(file => ({ reference: file.id, name: file.file.name, type: file.file.type, size: file.file.size }))));
    c.miscActivity?.draftAttachmentsSelected?.forEach((draftAttachment) =>
        formData.append("liveCaseDocumentIds", Number(draftAttachment.id))
    );

    formData.append("miscActivityTypeId", activity.miscActivityTypeId);
    formData.append("description", activity.description);
    formData.append("notes", activity.notes);
    formData.append("start", getDstCorrectedDateString(startDate));
    formData.append("finish", getDstCorrectedDateString(endDate));

    if (activity.filesToDelete.length)
        formData.append("filesToDelete", activity.filesToDelete);

    if (activity.caseActivityId) {
        formData.append("caseActivityId", activity.caseActivityId);
        try {
            const response = await axios.post(
                `${apiUrl}/updateretrospectivemiscactivity/${userId}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response.status === 200) {
                dispatch(setSnackAction("Successfully saved activity"));
                dispatch(
                    updateRetrospectiveMiscActivity(
                        {
                            eventTime: startDate,
                            duration:
                                (endDate.getTime() - startDate.getTime()) /
                                1000,
                            content: activity.notes,
                            miscActivityTypeId: activity.miscActivityTypeId,
                            caseActivityId: activity.caseActivityId,
                            attachments: [
                                ...(activity.existingFiles?.filter(
                                    (f) =>
                                        !activity.filesToDelete.includes(
                                            f.reference
                                        )
                                ) || []),
                                ...response.data,
                            ],
                            activityDescription: activity.description,
                            miscActivityGroup: miscActivityGroups.RETROSPECTIVE,
                        },
                        caseId
                    )
                );
            }
        } catch (error) {
            throw error;
        }
    } else {
        formData.append("caseId", caseId);
        formData.append("stringifiedCaseTimeEventIds", unallocatedCaseTimeEventIds.join(","));
        formData.append("isChargeable", c.caseSummary.isChargeable);
        try {
            const response = await axios.post(
                `${apiUrl}/addretrospectivemiscactivity/${userId}/${contractId}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response.status === 200) {
                const duration = (endDate.getTime() - startDate.getTime()) / 1000;
                dispatch(setSnackAction("Successfully saved activity"));
                dispatch(
                    addCaseEventAction(caseId, {
                        caseActivityId: response.data.caseActivityId,
                        adviserId: userId,
                        itemType: "Misc",
                        eventTime: startDate,
                        content: activity.notes,
                        miscActivityTypeId: activity.miscActivityTypeId,
                        direction: 2,
                        person: users[userId].name,
                        duration: duration,
                        attachments: response.data.fileDtos,
                        start: startDate,
                        finish: endDate,
                        isChargeable: response.data.isChargeable,
                        activityDescription: activity.description,
                        miscActivityGroup: miscActivityGroups.RETROSPECTIVE,
                    })
                );
                dispatch(incrementEventCount(caseId));
                dispatch(setAdjustCaseMinutes(caseId, duration / 60));

                if (response.data.isChargeable)
                    dispatch(updateCaseChargeableTime({ caseId, delta: duration }));
            }
        } catch (error) {
            throw error;
        }
    }
};

const getTimesheet = async () => {
    const { caseReducer } = store.getState();
    const { currentCaseId: caseId } = caseReducer;

    try {
        const response = await axios.get(`${apiUrl}/gettimesheet/${caseId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const saveCallAndNote = async ({
    caseId,
    activeCall,
    contactId,
    externalContactId,
    accountId,
    contractId,
    userId,
    note,
    caseIsChargeable,
    description,
    transferAllocatedCallTime,
    caseTimeEventGroupIds,
}) => {
    try {
        let queryString = `?transferAllocatedCallTime=${transferAllocatedCallTime}`;
        if (contactId) queryString += `&contactId=${contactId}`;
        if (externalContactId)
            queryString += `&externalContactId=${externalContactId}`;

        const response = await axios.post(
            `${apiUrl}/addcaseactivitycallandnote/${activeCall.callId}/${accountId}/${contractId}${queryString}`,
            {
                caseId,
                userId,
                reservationSid: activeCall.reservationSid,
                issue: note.issue,
                advice: note.advice,
                action: note.action,
                isChargeable: caseIsChargeable,
                description,
                caseTimeEventGroupIds,
            }
        );
        return response;
    } catch (error) {
        throw error;
    }
};

const saveCall = async ({
    caseId,
    callId,
    contactId,
    externalContactId,
    userId,
    accountId,
    contractId,
    caseIsChargeable,
    transferAllocatedCallTime,
}) => {
    try {
        let queryString = `?transferAllocatedCallTime=${transferAllocatedCallTime}`;
        if (contactId) queryString += `&contactId=${contactId}`;
        if (externalContactId)
            queryString += `&externalContactId=${externalContactId}`;

        const response = await axios.post(
            `${apiUrl}/addcaseactivitycall/${caseId}/${callId}/${userId}/${accountId}/${contractId}/${caseIsChargeable}${queryString}`
        );
        return response;
    } catch (error) {
        throw error;
    }
};

const updateNote = ({ caseActivityId, userId, note, caseId, description }) => {
    return axios.post(`${apiUrl}/updatecallnote/${caseActivityId}/${userId}`, {
        issue: note.issue,
        advice: note.advice,
        action: note.action,
        caseId,
        description,
    });
};

const addInternalNote = ({
    contractId,
    caseId,
    userId,
    noteText,
    description,
    caseTimeEventGroupIds,
}) => {
    return axios.post(`${apiUrl}/addinternalnote/${contractId}`, {
        caseId,
        userId,
        noteText,
        description,
        caseTimeEventGroupIds,
    });
};

const updateInternalNote = ({
    noteText,
    caseActivityId,
    userId,
    caseId,
    description,
}) => {
    return axios.post(`${apiUrl}/updateinternalnote`, {
        noteText,
        caseActivityId,
        userId,
        caseId,
        description,
    });
};

const saveCurrentMiscActivity = async ({ caseTimeEventGroupIds }) => {
    const { caseReducer, userReducer } = store.getState();
    const dispatch = store.dispatch;
    const { cases, currentCaseId: caseId } = caseReducer;

    const { userProfile, users } = userReducer;
    const { userId } = userProfile;

    const c = cases[caseId];
    const activity = c.miscActivity.current;
    const { contractId, isChargeable } = c.caseSummary;

    let formData = new FormData();

    c.miscActivity?.attachments?.forEach((file) =>
        formData.append("files", file.file)
    );
    c.miscActivity?.draftAttachmentsSelected?.forEach((draftAttachment) =>
        formData.append("liveCaseDocumentIds", Number(draftAttachment.id))
    );

    formData.append("miscActivityTypeId", activity.miscActivityTypeId);
    formData.append("description", activity.description);
    formData.append("notes", activity.notes);
    formData.append("userId", userId);

    if (activity.filesToDelete.length)
        formData.append("filesToDelete", activity.filesToDelete);

    if (activity.caseActivityId) {
        formData.append("caseActivityId", activity.caseActivityId);
        try {
            const response = await axios.post(
                `${apiUrl}/updatecurrentmiscactivity`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response.status === 200) {
                dispatch(setSnackAction("Successfully saved activity"));
                dispatch(
                    updateCurrentMiscActivity(
                        {
                            content: activity.notes,
                            miscActivityTypeId: activity.miscActivityTypeId,
                            caseActivityId: activity.caseActivityId,
                            attachments: [
                                ...(activity.existingFiles?.filter(
                                    (f) =>
                                        !activity.filesToDelete.includes(
                                            f.reference
                                        )
                                ) || []),
                                ...response.data.fileDtos,
                            ],
                            activityDescription: activity.description,
                            duration: response.data.duration,
                        },
                        caseId
                    )
                );
                dispatch(resetMiscActivity());
            }
        } catch (error) {
            throw error;
        }
    } else {
        formData.append("caseId", caseId);
        for (let i = 0; i < caseTimeEventGroupIds.length; i++)
            formData.append(
                `caseTimeEventGroupIds[${i}]`,
                caseTimeEventGroupIds[i]
            );
        formData.append("isChargeable", isChargeable);
        formData.append("contractId", contractId);
        try {
            const response = await axios.post(
                `${apiUrl}/addcurrentmiscactivity`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response.status === 200) {
                const { duration } = response.data;
                dispatch(setSnackAction("Successfully saved activity"));
                dispatch(
                    addCaseEventAction(caseId, {
                        caseActivityId: response.data.caseActivityId,
                        adviserId: userId,
                        itemType: "Misc",
                        eventTime: new Date(),
                        content: activity.notes,
                        miscActivityTypeId: activity.miscActivityTypeId,
                        direction: 2,
                        person: users[userId].name,
                        duration,
                        attachments: response.data.fileDtos,
                        isChargeable,
                        activityDescription: activity.description,
                        miscActivityGroup: miscActivityGroups.CURRENT,
                    })
                );
                dispatch(incrementEventCount(caseId));
                dispatch(setAdjustCaseMinutes(caseId, duration / 60));
                dispatch(resetMiscActivity());

                if (response.data.isChargeable)
                    dispatch(
                        updateCaseChargeableTime({ caseId, delta: duration })
                    );
            }
        } catch (error) {
            throw error;
        }
    }
};

const getEmailByCaseActivityId = async (caseActivityId) =>
    axios.get(`${apiUrl}/getemailbycaseactivityid/${caseActivityId}`);

const reassignCaseActivity = async (
    newCaseId,
    caseId,
    caseActivityId,
    userId
) =>
    axios.get(
        `${apiUrl}/reassigncaseactivity/${caseActivityId}/${newCaseId}/${caseId}/${userId}`
    );

const adjustCaseActivityDuration = async (
    caseActivityId,
    adviserId,
    duration,
    caseId
) =>
    await axios.post(`${apiUrl}/adjustcaseactivityduration`, {
        caseActivityId,
        adviserId,
        duration,
        caseId,
    });

const getSeekAdviceActivity = async (caseActivityMiscId) => {
    try {
        const response = await axios.get(`${apiUrl}/CaseActivity/Misc/SeekAdvice/${caseActivityMiscId}`);
        return response.data;
    }
    catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Failed to fetch Seek Advice Activity");
    }
}

const addSeekAdviceActivity = async ({ taskSid, caseId }) => {
    try {
        const response = await axios.post(`${apiUrl}/CaseActivity/Misc/SeekAdvice`, { taskSid, caseId });
        return response.data;
    }
    catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Failed to add Seek Advice Activity");
    }
}

const caseActivityService = {
    sendActivities,
    saveRetrospectiveMiscActivity,
    getTimesheet,
    saveCallAndNote,
    saveCall,
    updateNote,
    addInternalNote,
    updateInternalNote,
    saveCurrentMiscActivity,
    getEmailByCaseActivityId,
    reassignCaseActivity,
    adjustCaseActivityDuration,
    getSeekAdviceActivity,
    addSeekAdviceActivity,
};

export default caseActivityService;
