import axios from "../plugins/axios";
import openAiService from "./openAiService";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const sendEmail = ({
    caseId,
    contractId,
    event,
    files,
    draftAttachmentIds,
    caseTimeEventGroupIds,
    liveCaseDocumentIds,
    azureFileIds,
    sizeExcludingLiveDocs,
    aIRating,
    plainText,
}) => {
    let formData = new FormData();

    formData.append("eventJson", JSON.stringify(event));
    for (let i = 0; i < caseTimeEventGroupIds.length; i++)
        formData.append(
            `caseTimeEventGroupIds[${i}]`,
            caseTimeEventGroupIds[i]
        );

    formData.append("stringifiedFiles", JSON.stringify(files.map(file => ({ reference: file.id, name: file.file.name, type: file.file.type, size: file.file.size, azureFileId: file.azureFileId }))));

    for (const draftAttachmentId of draftAttachmentIds) {
        formData.append("draftAttachmentIds", draftAttachmentId);
    }

    for (const liveCaseDocumentId of liveCaseDocumentIds) {
        formData.append("liveCaseDocumentIds", liveCaseDocumentId);
    }

    for (const azureFileId of azureFileIds) {
        formData.append("azureFileIds", azureFileId);
    }

    formData.append("sizeExcludingLiveDocs", sizeExcludingLiveDocs);
    if (aIRating) {
        formData.append("legallyAccurate", aIRating[0]);
        formData.append("risk", aIRating[1]);
        formData.append("languageQuality", aIRating[2]);
        formData.append("pragmaticAdvice", aIRating[3]);
        formData.append("clientCentric", aIRating[4]);
        formData.append("answeredQuestion", aIRating[5]);
    }
    
    formData.append("plainText", plainText);

    return axios.post(`${apiUrl}/sendreply/${caseId}/${contractId}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

const setEmailAiGeneratedResponse = async ({
    emailId,
    aiGeneratedResponse,
}) => {
    try {
        await axios.patch(`${apiUrl}/email`, {
            emailId,
            aiGeneratedResponse,
        });
    } catch (e) {
        return Promise.reject(
            e?.message || "Could not set Email AiGeneratedResponse"
        );
    }
};

const emailService = {
    sendEmail,
    setEmailAiGeneratedResponse,
};

export default emailService;
