import React from "react";
import { useParams } from "react-router-dom";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import {
    Typography,
    Divider as MuiDivider,
    Grid
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import AdviserCard from "../cards/AdviserCard";
import InboundQueriesCard from "../cards/InboundQueriesCard";
import MyRecentCasesCard from "../cards/MyRecentCasesCard";
import AdviserThermometerCard from "../cards/AdviserThermometerCard";
import UtilisationCard from "../cards/UtilisationCard";
import Loader from "../components/Loader";
import UserOpenCasesCard from "../cards/UserOpenCasesCard";
import TimesheetCard from "../cards/TimesheetCard";

const Divider = styled(MuiDivider)(spacing);

const Adviser = ({ userState }) => {
    const { userId } = useParams();

    const user = userState.users[userId];    

    return (
        !user ? <Loader /> :
        <React.Fragment>
            <Helmet title={"Adviser " + userId} />
            <Typography variant="h3">{userState.users[userId].name}</Typography>

            <Divider my={6} />

            <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <AdviserCard adviser={user} />
                        <Grid>
                            <UserOpenCasesCard userProfile={user} isMyData="false" />
                        </Grid>
                        <Grid>
                            <AdviserThermometerCard userProfile={user} />
                        </Grid>
                        <UtilisationCard adviser={userState.users[userId]} />
                    </Grid>
                <Grid item xs={12} md={9}>
                        <InboundQueriesCard userProfile={user} />
                        <MyRecentCasesCard adviserId={userId} adviserName={user.name} />
                        <TimesheetCard adviserId={userId} adviserName={user.name} displayTimesheet={userState.userProfile.userId == userId} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default connect(state => ({ userState: state.userReducer }))(Adviser);