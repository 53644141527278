import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "../plugins/axios";

import {
    Box,
    Button as MuiIconButton,
    CardContent,
    Card as MuiCard,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Divider as MuiDivider,
    Table,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    TextField,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import {
    Edit,
    Replay,
    Save,
    ErrorOutline as ValidationIcon,
} from "@material-ui/icons";

import { setSnackAction } from "../redux/actions/snackActions";
import { setPartnershipsAction } from "../redux/actions/configActions";
import { emailIsValid } from "../utils/validators";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const formStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
`;

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const PartnershipsAdmin = ({ dispatch, configState }) => {
    const formClasses = formStyles();

    const [partnershipId, setPartnershipId] = useState("");
    const [mode, setMode] = useState("view");
    const [serviceMessage, setServiceMessage] = useState("");
    const [fromEmail, setFromEmail] = useState("");
    const [email, setEmail] = useState("");
    const [emailSLA, setEmailSLA] = useState("");
    const [callbackSLA, setCallbackSLA] = useState("");
    const [emailWarning, setEmailWarning] = useState("");
    const [callbackWarning, setCallbackWarning] = useState("");
    const [validSLAs, setValidSLAs] = useState("false");
    const [signoffText, setSignoffText] = useState("");
    const [requestMembershipDetails, setRequestMembershipDetails] =
        useState("");

    const handlePartnershipChange = (e) => {
        setPartnershipId(e.target.value);
    };

    const handleEdit = () => {
        setServiceMessage(
            configState.partnerships[partnershipId].serviceMessage
        );
        setFromEmail(configState.partnerships[partnershipId].fromEmail);
        setEmail(configState.partnerships[partnershipId].email);
        setSignoffText(configState.partnerships[partnershipId].signoffText);
        setEmailSLA(configState.partnerships[partnershipId].emailSLA);
        setCallbackSLA(configState.partnerships[partnershipId].callbackSLA);
        setEmailWarning(configState.partnerships[partnershipId].emailWarning);
        setCallbackWarning(
            configState.partnerships[partnershipId].callbackWarning
        );
        setRequestMembershipDetails(
            configState.partnerships[partnershipId].requestMembershipDetails
        );
        setMode("edit");
    };

    const handleReset = () => {
        setServiceMessage("");
        setFromEmail("");
        setEmail("");
        setSignoffText("");
        setEmailSLA("8.5");
        setCallbackSLA("4");
        setEmailWarning("6.5");
        setCallbackWarning("3.5");
        setRequestMembershipDetails("");
        setMode("view");
    };

    const validate = () => {
        let temp = {};
        if (fromEmail)
            temp.fromEmail = emailIsValid(fromEmail)
                ? ""
                : "email entered is not valid";
        if (email)
            temp.email = emailIsValid(email)
                ? ""
                : "email entered is not valid";

        return Object.values(temp).every((x) => x == "");
    };

    const handleSave = async () => {
        if (validate()) {
            setMode("saving");

            let response = await axios.post(
                `${apiUrl}/updatepartnership/${partnershipId}`,
                {
                    serviceMessage,
                    fromEmail,
                    email,
                    emailSLA,
                    callbackSLA,
                    emailWarning,
                    callbackWarning,
                    signoffText,
                    requestMembershipDetails,
                }
            );
            if (response.status === 200) {
                dispatch(setSnackAction("Partnership saved!", "success"));
                dispatch(
                    setPartnershipsAction(
                        Object.values(configState.partnerships).map((p) =>
                            p.id === partnershipId
                                ? {
                                      ...p,
                                      serviceMessage,
                                      fromEmail,
                                      email,
                                      emailSLA,
                                      callbackSLA,
                                      emailWarning,
                                      callbackWarning,
                                      signoffText,
                                      requestMembershipDetails,
                                  }
                                : p
                        )
                    )
                );
                handleReset();
            }
            setMode("view");
        } else {
            dispatch(
                setSnackAction("Please supply valid from email", "warning")
            );
            return;
        }
    };

    useEffect(() => {
        if (partnershipId) {
            handleReset();
        }
    }, [partnershipId]);

    useEffect(() => {
        //if either of the warnings are greater than the SLA value then set the validSLA (this is used to display the validation warning or not)
        setValidSLAs(
            parseFloat(callbackWarning) < parseFloat(callbackSLA) &&
                parseFloat(emailWarning) < parseFloat(emailSLA)
        );
    }, [callbackSLA, emailSLA, callbackWarning, emailWarning]);

    return (
        <React.Fragment>
            <Helmet title="Partnerships" />
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h3" gutterBottom display="inline">
                    Partnerships
                </Typography>
                <FormControl
                    style={{ paddingBottom: "8px" }}
                    className={formClasses.formControl}
                >
                    <InputLabel>Partnership</InputLabel>
                    <Select
                        value={partnershipId}
                        onChange={handlePartnershipChange}
                    >
                        {Object.values(configState.partnerships)
                            .sort((a, b) =>
                                a.title > b.title
                                    ? 1
                                    : b.title > a.title
                                    ? -1
                                    : 0
                            )
                            .map((p) => (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.title}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>
            <Divider my={6} />
            <Card mb={6}>
                <Box mt={3} mr={3} display="flex" justifyContent="flex-end">
                    {mode === "view" && partnershipId && (
                        <IconButton onClick={handleEdit}>
                            <Edit />
                        </IconButton>
                    )}
                    {mode === "edit" && validSLAs && (
                        <IconButton
                            disabled={
                                configState.partnerships[partnershipId]
                                    .serviceMessage === serviceMessage &&
                                configState.partnerships[partnershipId]
                                    .fromEmail === fromEmail &&
                                configState.partnerships[partnershipId]
                                    .email === email &&
                                configState.partnerships[partnershipId]
                                    .signoffText === signoffText &&
                                configState.partnerships[partnershipId]
                                    .emailSLA === emailSLA &&
                                configState.partnerships[partnershipId]
                                    .callbackSLA === callbackSLA &&
                                configState.partnerships[partnershipId]
                                    .emailWarning === emailWarning &&
                                configState.partnerships[partnershipId]
                                    .callbackWarning === callbackWarning &&
                                configState.partnerships[partnershipId]
                                    .requestMembershipDetails ===
                                    requestMembershipDetails
                            }
                            onClick={handleSave}
                        >
                            <Save />
                        </IconButton>
                    )}
                    {mode === "edit" && (
                        <IconButton onClick={handleReset}>
                            <Replay />
                        </IconButton>
                    )}
                    {mode === "saving" && <CircularProgress size={21} />}
                </Box>
                <CardContent>
                    <Box display="flex" justifyContent="center">
                        {partnershipId ? (
                            <Table style={{ width: "60%" }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                Service Message
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {mode === "view" && (
                                                <Typography variant="body2">
                                                    {
                                                        configState
                                                            .partnerships[
                                                            partnershipId
                                                        ].serviceMessage
                                                    }
                                                </Typography>
                                            )}
                                            {mode === "edit" && (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    multiline
                                                    value={serviceMessage}
                                                    onChange={(e) =>
                                                        setServiceMessage(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                From Email
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {mode === "view" && (
                                                <Typography variant="body2">
                                                    {
                                                        configState
                                                            .partnerships[
                                                            partnershipId
                                                        ].fromEmail
                                                    }
                                                </Typography>
                                            )}
                                            {mode === "edit" && (
                                                <TextField
                                                    inputProps={{
                                                        maxLength: 320,
                                                    }}
                                                    style={{ width: "100%" }}
                                                    multiline
                                                    value={
                                                        fromEmail == undefined
                                                            ? ""
                                                            : fromEmail.trim()
                                                    }
                                                    onChange={(e) =>
                                                        setFromEmail(
                                                            e.target.value.trim()
                                                        )
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                Email
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {mode === "view" && (
                                                <Typography variant="body2">
                                                    {
                                                        configState
                                                            .partnerships[
                                                            partnershipId
                                                        ].email
                                                    }
                                                </Typography>
                                            )}
                                            {mode === "edit" && (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    multiline
                                                    value={
                                                        email == undefined
                                                            ? ""
                                                            : email.trim()
                                                    }
                                                    onChange={(e) =>
                                                        setEmail(
                                                            e.target.value.trim()
                                                        )
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                Email Signoff Text
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {mode === "view" && (
                                                <Typography variant="body2">
                                                    {
                                                        configState
                                                            .partnerships[
                                                            partnershipId
                                                        ].signoffText
                                                    }
                                                </Typography>
                                            )}
                                            {mode === "edit" && (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    multiline
                                                    value={signoffText}
                                                    onChange={(e) =>
                                                        setSignoffText(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                Request Membership Details Text
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {mode === "view" && (
                                                <Typography variant="body2">
                                                    {
                                                        configState
                                                            .partnerships[
                                                            partnershipId
                                                        ]
                                                            .requestMembershipDetails
                                                    }
                                                </Typography>
                                            )}
                                            {mode === "edit" && (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    multiline
                                                    value={
                                                        requestMembershipDetails
                                                    }
                                                    onChange={(e) =>
                                                        setRequestMembershipDetails(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{ borderBottom: "none" }}
                                        >
                                            <Typography variant="h6">
                                                SLA & Warning Settings
                                                (1day=8.5hrs)
                                            </Typography>
                                        </TableCell>
                                        {!validSLAs && (
                                            <TableCell
                                                style={{
                                                    borderBottom: "none",
                                                    color: "red",
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    Invalid Warning/SLA
                                                    combination values{" "}
                                                    <ValidationIcon />
                                                </Typography>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                Callbacks Response SLA (default
                                                4hrs)
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {mode === "view" && (
                                                <Typography variant="body2">
                                                    {
                                                        configState
                                                            .partnerships[
                                                            partnershipId
                                                        ].callbackSLA
                                                    }
                                                </Typography>
                                            )}
                                            {mode === "edit" && (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    multiline
                                                    value={callbackSLA}
                                                    onChange={(e) =>
                                                        setCallbackSLA(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                Callbacks Response Warning
                                                (default 3.5hrs)
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {mode === "view" && (
                                                <Typography variant="body2">
                                                    {
                                                        configState
                                                            .partnerships[
                                                            partnershipId
                                                        ].callbackWarning
                                                    }
                                                </Typography>
                                            )}
                                            {mode === "edit" && (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    multiline
                                                    value={callbackWarning}
                                                    onChange={(e) =>
                                                        setCallbackWarning(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                Email Response SLA (default
                                                8.5hrs)
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {mode === "view" && (
                                                <Typography variant="body2">
                                                    {
                                                        configState
                                                            .partnerships[
                                                            partnershipId
                                                        ].emailSLA
                                                    }
                                                </Typography>
                                            )}
                                            {mode === "edit" && (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    multiline
                                                    value={emailSLA}
                                                    onChange={(e) =>
                                                        setEmailSLA(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">
                                                Email Response Warning (default
                                                6.5hrs)
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {mode === "view" && (
                                                <Typography variant="body2">
                                                    {
                                                        configState
                                                            .partnerships[
                                                            partnershipId
                                                        ].emailWarning
                                                    }
                                                </Typography>
                                            )}
                                            {mode === "edit" && (
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    multiline
                                                    value={emailWarning}
                                                    onChange={(e) =>
                                                        setEmailWarning(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        ) : (
                            <Typography variant="body2">
                                Please select a Partnership
                            </Typography>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({ configState: state.configReducer });

export default connect(mapStateToProps)(PartnershipsAdmin);
