import * as types from '../constants';

export function setAccountNote(text, accountId) {
	return {
		type: types.SET_ACCOUNT_NOTE,
        text,
        accountId
	}
}

export function setDisplayAccountSearchInTab(display) {
    return {
        type: types.SET_DISPLAY_ACCOUNT_SEARCH_IN_TAB,
        display
    }
}

export function setCurrentAccountAction(accountId) {
    return {
        type: types.SET_CURRENT_ACCOUNT,
        accountId: accountId
    }
}

export function removeAccountTabAction(accountId) {
    return {
        type: types.REMOVE_ACCOUNT_TAB,
        accountId: accountId
    }
}

export function setAccountCriteriaAccountName(accountName) {
    return {
        type: types.SET_ACC_CRITERIA_ACCOUNT_NAME,
        accountName
    }
}

export function setAccountCriteriaPostCode(postCode) {
    return {
        type: types.SET_ACC_CRITERIA_POST_CODE,
        postCode
    }
}

export function setAccountCriteriaPartnerNumber(partnerNumber) {
    return {
        type: types.SET_ACC_CRITERIA_PARTNER_NUMBER,
        partnerNumber
    }
}

export function setAccountCriteriaPartnershipId(partnershipId) {

    return {
        type: types.SET_ACC_CRITERIA_PARTNERSHIP_ID,
        partnershipId
    }
}


export function setAccountCriteriaStatus(accountStatus) {

    return {
        type: types.SET_ACC_CRITERIA_ACCOUNT_STATUS,
        accountStatus
    }
}

export function setAccountNoteEdited(edited, accountId) {
    return {
        type: types.SET_ACCOUNT_NOTE_EDITED,
        edited,
        accountId
    }
}

export function setAccountCriteriaAdviserId(adviserId) {
    return {
        type: types.SET_ACC_CRITERIA_ADVISER_ID,
        adviserId
    }
}

export function setCallbackRequestData(payload) {
    return {
        type: types.SET_CALLBACK_REQUEST_DATA,
        payload,
    }
}

export function setCallbackRequestOpen(isOpen) {
    return {
        type: types.SET_CALLBACK_REQUEST_OPEN,
        isOpen,
    }
}

