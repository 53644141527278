import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { dashboard as dashboardRoutes } from "./index";
import FlexSidebar from "../components/FlexSidebar";
import DashboardLayout from "../layouts/DashboardLayout";
import AuthLayout from "../layouts/AuthLayout";
import Page404 from "../mui/auth/Page404";
import { Box } from "@material-ui/core";
import { useAuth } from "../contexts/authContext";
import routeUtils from "../utils/routeUtils";
import userRoles from "../constants/userRoles";
import Cases from "../pages/Cases";
import CaseHistoryExport from "../pages/CaseHistoryExport";

const childRoutes = (Layout, routes, hasRole) =>
    routeUtils.filterAllowedRoutes(routes, hasRole).map(({ children, path, viewtype, component: Component, requiredRoles, forbiddenRoles }, index) =>
        children ? (
            // Route item with children
            children.map(({ path, viewtype, component: Component, requiredRoles, forbiddenRoles }, index) =>
                routeUtils.isRouteAllowed({ requiredRoles, forbiddenRoles }, hasRole) ? (
                    <Route
                        key={index}
                        path={path}
                        exact
                        render={(props) => (
                            <Layout>
                                <Component viewtype={viewtype} {...props} />
                            </Layout>
                        )}
                    />
                ) : (
                    <Redirect key={index} from={path} to="/mycases" exact />
                )
            )
        ) : routeUtils.isRouteAllowed({ requiredRoles, forbiddenRoles, path }, hasRole) ? (
            // Route item without children
            <Route
                key={index}
                path={path}
                exact
                render={(props) => (
                    <Layout>
                        <Component viewtype={viewtype} {...props} />
                    </Layout>
                )}
            />
        ) : (
            <Redirect key={index} from={path} to="/mycases" exact />
        )
    );

const Routes = () => {
    const { hasRole } = useAuth();
    return (
        <Box display="flex">
            <Box flexGrow={1}>
                <Switch>
                    {hasRole(userRoles.HRC_ASSOCIATE) && (
                        <Route
                            exact
                            path="/"
                            render={(props) => (
                                <DashboardLayout>
                                    <Cases viewtype="Advisor" {...props} />
                                </DashboardLayout>
                            )}
                        />
                    )}
                    {childRoutes(DashboardLayout, dashboardRoutes, hasRole)}
                    <Route
                        exact
                        path="/casehistoryexport/:guid"
                        render={(props) => (
                            <AuthLayout>
                                <CaseHistoryExport {...props} />
                            </AuthLayout>
                        )}
                    />
                    <Route
                        render={() => (
                            <AuthLayout>
                                <Page404 />
                            </AuthLayout>
                        )}
                    />
                </Switch>
            </Box>
            <FlexSidebar />
        </Box>
    );
};

export default Routes;
