import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ConfirmCompleteTaskDialog from "../components/ConfirmCompleteTaskDialog";
import FileViewerModal from "../components/FileViewerModal";
import ConfirmCloseCaseDialog from "../components/ConfirmCloseCaseDialog";
import {
    setCurrentCaseAction,
    removeCaseTabAction,
    setConfirmCompleteTaskInfo,
    setDisplayCaseSearchInTab,
    setCriteriaAccountName,
    setCriteriaPartnerNumber,
    setCriteriaEmployee,
    setCriteriaPartnershipId,
    setCriteriaAdviserId,
    setCriteriaCaseTypeId,
    setCriteriaModifiedDateFrom,
    setCriteriaModifiedDateTo,
    setCriteriaCreatedDateFrom,
    setCriteriaCreatedDateTo,
    setShowEditor,
    setConfirmCloseOpenAction,
    setProcessUnallocatedTimeRequestStatus,
    setGetUnallocatedTimeRequestStatus,
    setUnallocatedCaseTimeEventIds,
    setUnallocatedSeconds,
    resetMiscActivity,
    resetCaseHistoryFilters,
} from "../redux/actions/caseActions";
import requestStatus from "../constants/requestStatus";
import {
    setCurrentAccountAction,
    removeAccountTabAction,
    setDisplayAccountSearchInTab,
    setAccountCriteriaAccountName,
    setAccountCriteriaPostCode,
    setAccountCriteriaPartnerNumber,
    setAccountCriteriaPartnershipId,
    setAccountCriteriaStatus,
} from "../redux/actions/accountActions";
import FullScreenEmail from "../components/FullScreenEmail";
import { spacing } from "@material-ui/system";
import {
    AppBar,
    Button as MuiIconButton,
    Box,
    Hidden,
    CssBaseline,
    Paper as MuiPaper,
    withWidth,
    Tabs,
    Tab as MuiTab,
    Tooltip,
    Typography,
    Backdrop,
    CircularProgress,
    Fab,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Close as CloseIcon } from "@material-ui/icons";
import { isWidthUp } from "@material-ui/core/withWidth";
import CaseResearchDialog from "../components/CaseResearchDialog";
import { getUnallocatedCaseTime } from "../redux/actions/thunks";
import closeCaseDialogStepConstants from "../constants/closeCaseDialogStepConstants";
import { useAuth } from "../contexts/authContext";
import SeekAdvicePreviewDialog from "../components/dialogs/SeekAdvicePreviewDialog";
import { EmailDraftProvider } from "../contexts/emailDraftContext";

const drawerWidth = 260;

const Tab = styled(MuiTab)`
    min-width: 100;
`;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
    display: flex;
    min-height: 100vh;
`;

const Drawer = styled.div`
    ${(props) => props.theme.breakpoints.up("xl")} {
        width: ${drawerWidth}px;
        flex-shrink: 0;
    }
`;

const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
    flex: 1;
    background: ${(props) => props.theme.body.background};

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: none;
    }

    .MuiPaper-root .MuiPaper-root {
        box-shadow: none;
    }
`;

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        "& ~ div.MuiPaper-root": {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    fab: {
        position: "fixed",
        zIndex: 100,
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    },
}));

const CaseTab = ({ id, handleTabChange, index, closeTab, tabTitle, tooltipText }) => {
    return (
        <Tab
            key={id}
            wrapped
            onClick={(e) => handleTabChange(e, index)}
            label={
                <Tooltip title={tooltipText == null ? "" : tooltipText}>
                    <Typography>
                        {tabTitle}

                        <CloseIcon fontSize="small" style={{ position: "absolute", right: 0, top: 0 }} onClick={(e) => closeTab(e, id)} />
                    </Typography>
                </Tooltip>
            }
        />
    );
};

const DashboardLayout = ({
    children,
    routes,
    width,
    caseState,
    accountState,
    setCurrentCase,
    removeCaseTab,
    setConfirmCompleteTaskInfo,
    setDisplayCaseSearchInTab,
    setCriteriaAccountName,
    setCriteriaPartnerNumber,
    setCriteriaEmployee,
    setCriteriaPartnershipId,
    setCriteriaAdviserId,
    setCriteriaCaseTypeId,
    setCriteriaModifiedDateFrom,
    setCriteriaModifiedDateTo,
    setCriteriaCreatedDateFrom,
    setCriteriaCreatedDateTo,
    setDisplayAccountSearchInTab,
    setCurrentAccount,
    removeAccountTab,
    setAccountCriteriaAccountName,
    setAccountCriteriaPostCode,
    setAccountCriteriaPartnerNumber,
    setAccountCriteriaPartnershipId,
    setAccountCriteriaStatus,
    configState,
}) => {
    const theme = useTheme();
    const { ignoreBrowserBehaviour } = useAuth();
    const [currentCaseTab, setCurrentCaseTab] = useState(0);

    const [sideMenuOpen, setSideMenuOpen] = useState(false);

    const isLaptop = useMediaQuery(theme.breakpoints.down("lg"));

    const caseIds = Object.keys(caseState.cases);
    const accountIds = Object.keys(accountState.accounts);

    const processUnallocatedTimeRequestStatus = caseState.processUnallocatedTimeRequestStatus;
    const getUnallocatedTimeRequestStatus = caseState.getUnallocatedTimeRequestStatus;
    const unallocatedSeconds = caseState.unallocatedSeconds;
    const unallocatedCaseTimeEventIds = caseState.unallocatedCaseTimeEventIds;

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const { confirmCloseOpen } = useSelector((state) => state.caseReducer);
    const setConfirmCloseOpen = (isOpen) => dispatch(setConfirmCloseOpenAction(isOpen));

    const [caseIdToClose, setCaseIdToClose] = useState(null);

    const [dialogStep, setDialogStep] = useState(closeCaseDialogStepConstants.discardUnfinishedWork);

    const classes = useStyles();

    const handleCaseTabChange = (e, newValue) => {
        dispatch(setShowEditor(false));
        setCurrentAccount(null);
        setCurrentCase(parseInt(caseIds[newValue]));
        history.push("/case");
    };

    const handleAccountTabChange = (e, newValue) => {
        setCurrentAccount(accountIds[newValue]);
        history.push("/account");
    };

    const handleTabChangeToCaseSearch = (e) => {
        setCurrentAccount(null);
        history.push("/cases");
    };

    const handleTabChangeToAccountSearch = (e) => {
        setCurrentAccount(null);
        history.push("/accounts");
    };
    const handleDrawerToggle = () => {
        setSideMenuOpen(!sideMenuOpen);
    };

    const closeCaseTab = (caseId) => {
        setCurrentCaseTab(0);
        removeCaseTab(caseId);
        setCaseIdToClose(null);
        if (caseIds.length === 1 && location.pathname === "/case") {
            history.push("/cases");
            setCurrentCase(null);
        }
        cancelCloseCaseTab();
    };

    const handleClickCloseCaseTab = (e, caseId) => {
        if (e !== null) e.stopPropagation();
        setCaseIdToClose(caseId);
        const c = caseState.cases[caseId];

        if (c.reply.editorValue || c.callNote.text.issue || c.callNote.text.advice || c.callNote.text.action || c.internalNote.text) {
            setDialogStep(closeCaseDialogStepConstants.discardUnfinishedWork);
            setConfirmCloseOpen(true);
        } else if (!c.caseSummary?.description) {
            setDialogStep(closeCaseDialogStepConstants.caseDescriptionWarning);
            setConfirmCloseOpen(true);
        } else if (!c.employees || c.employees.length === 0) {
            setDialogStep(closeCaseDialogStepConstants.caseEmployeeWarning);
            setConfirmCloseOpen(true);
        } else if (c.caseFilesTreeChanged) {
            setDialogStep(closeCaseDialogStepConstants.caseFilesTreeChangeWarning);
            setConfirmCloseOpen(true);
        } else confirmCloseCaseTab(caseId);
    };

    const confirmCloseCaseTab = (caseId) => {
        setConfirmCloseOpen(false);
        dispatch(resetCaseHistoryFilters(caseId));
        dispatch(getUnallocatedCaseTime({ caseId }));
    };

    const cancelCloseCaseTab = () => {
        if (parseInt(caseIdToClose, 10)) dispatch(resetMiscActivity({ caseId: parseInt(caseIdToClose, 10) }));
        setCaseIdToClose(null);
        setDialogStep(closeCaseDialogStepConstants.discardUnfinishedWork);
        dispatch(setUnallocatedCaseTimeEventIds({ caseTimeEventIds: [] }));
        dispatch(setUnallocatedSeconds({ seconds: null }));
        setConfirmCloseOpen(false);
    };

    window.onbeforeunload = (e) => {
        if (!ignoreBrowserBehaviour) {
            for (const [caseId, c] of Object.entries(caseState.cases)) {
                if (
                    c.reply.editorValue ||
                    c.callNote.text.issue ||
                    c.callNote.text.advice ||
                    c.callNote.text.action ||
                    c.internalNote.text ||
                    Object.keys(caseState.cases).length > 0
                ) {
                    e.preventDefault();
                    e.returnValue = "";
                }
            }
            return;
        } else {
            return;
        }
    };

    const closeAccountTab = (e, accountId) => {
        e.stopPropagation();
        removeAccountTab(accountId);
        if (accountIds.length === 1 && location.pathname === "/account") history.push("/accounts");
    };

    const closeCaseSearchTab = (e) => {
        e.stopPropagation();
        setCriteriaEmployee("");
        setCriteriaAccountName("");
        setCriteriaPartnerNumber("");
        setCriteriaPartnershipId(null);
        setCriteriaAdviserId(null);
        setCriteriaCaseTypeId(null);
        setCriteriaModifiedDateFrom(null);
        setCriteriaModifiedDateTo(null);
        setCriteriaCreatedDateFrom(null);
        setCriteriaCreatedDateTo(null);
        setDisplayCaseSearchInTab(false);
    };

    const closeAccountSearchTab = (e) => {
        e.stopPropagation();
        setAccountCriteriaAccountName("");
        setAccountCriteriaPostCode("");
        setAccountCriteriaPartnerNumber("");
        setAccountCriteriaPartnershipId(null);
        setAccountCriteriaStatus("");

        setDisplayAccountSearchInTab(false);
    };

    useEffect(() => {
        if (caseState.currentCaseId !== null) {
            let index = caseIds.indexOf(caseState.currentCaseId.toString());
            if (index > -1) setCurrentCaseTab(caseIds.indexOf(caseState.currentCaseId.toString()));
        }
    }, [caseState.currentCaseId]);

    useEffect(() => {
        if (accountState.currentAccountId !== null) {
            let index = accountIds.indexOf(accountState.currentAccountId.toString());
            if (index > -1)
                //as the tab is made up of combined case and account record we need to include this into the calcuation of which tab item in the strip to highliught
                //
                var casesDisplayed = caseIds.length;

            setCurrentCaseTab(casesDisplayed + accountIds.indexOf(accountState.currentAccountId.toString()));
        }
    }, [accountState.currentAccountId]);

    useEffect(() => {
        if (processUnallocatedTimeRequestStatus === requestStatus.RESOLVED) {
            // Case time was assigned successfully. Close the case tab
            if (caseIdToClose) closeCaseTab(caseIdToClose);

            dispatch(
                setProcessUnallocatedTimeRequestStatus({
                    requestStatus: requestStatus.IDLE,
                })
            );
            return;
        }

        if (processUnallocatedTimeRequestStatus === requestStatus.REJECTED) {
            // There was an error. Reset request status
            dispatch(
                setProcessUnallocatedTimeRequestStatus({
                    requestStatus: requestStatus.IDLE,
                })
            );
        }
    }, [processUnallocatedTimeRequestStatus, caseIdToClose]);

    useEffect(() => {
        if (getUnallocatedTimeRequestStatus === requestStatus.RESOLVED) {
            if (unallocatedSeconds) {
                // Unallocated time was fetched successfully. Open the modal
                setDialogStep(closeCaseDialogStepConstants.handleUnallocatedTime);
                setConfirmCloseOpen(true);
            }
            // Unallocated time was < 30s. Close the case tab
            else if (caseIdToClose) closeCaseTab(caseIdToClose);

            dispatch(
                setGetUnallocatedTimeRequestStatus({
                    requestStatus: requestStatus.IDLE,
                })
            );
        }

        if (getUnallocatedTimeRequestStatus === requestStatus.REJECTED) {
            // There was an error. Reset request status
            dispatch(
                setGetUnallocatedTimeRequestStatus({
                    requestStatus: requestStatus.IDLE,
                })
            );
        }
    }, [getUnallocatedTimeRequestStatus, caseIdToClose]);

    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            {sideMenuOpen && isLaptop && (
                <Drawer>
                    <Hidden xlUp implementation="js">
                        <Sidebar PaperProps={{ style: { width: drawerWidth } }} variant="temporary" open={sideMenuOpen} onClose={handleDrawerToggle} />
                    </Hidden>
                </Drawer>
            )}

            {!sideMenuOpen && (
                <Drawer>
                    <Hidden xlUp implementation="js">
                        <Sidebar PaperProps={{ style: { width: drawerWidth } }} variant="temporary" open={sideMenuOpen} onClose={handleDrawerToggle} />
                    </Hidden>
                    <Hidden lgDown implementation="js">
                        <Sidebar PaperProps={{ style: { width: drawerWidth } }} />
                    </Hidden>
                </Drawer>
            )}
            <AppContent>
                <ConfirmCompleteTaskDialog
                    open={caseState.confirmCompleteTask.open}
                    channelType={caseState.confirmCompleteTask.info.channelType}
                    sid={caseState.confirmCompleteTask.info.sid}
                    setTaskInfo={setConfirmCompleteTaskInfo}
                    emailAllocated={caseState.confirmCompleteTask.emailAllocated}
                    taskCompletionReasons={Object.values(configState.taskCompletionReasons)}
                    taskSid={caseState.confirmCompleteTask.info.taskSid}
                />
                <EmailDraftProvider>
                    <FullScreenEmail />
                </EmailDraftProvider>                
                <SeekAdvicePreviewDialog />
                <FileViewerModal />
                <ConfirmCloseCaseDialog
                    open={confirmCloseOpen}
                    handleClose={cancelCloseCaseTab}
                    handleConfirm={confirmCloseCaseTab}
                    dialogStep={dialogStep}
                    unallocatedSeconds={unallocatedSeconds}
                    unallocatedCaseTimeEventIds={unallocatedCaseTimeEventIds}
                    setDialogStep={setDialogStep}
                    caseId={caseIdToClose}
                />
                <CaseResearchDialog />
                <Backdrop className={classes.backdrop} open={caseState.backdropOpen}>
                    <CircularProgress style={{ color: "white" }} />
                </Backdrop>
                <Header onDrawerToggle={handleDrawerToggle} drawerOpen={sideMenuOpen} />
                <MainContent p={isWidthUp("xl", width) ? 10 : 5} pt={5}>
                    {!!caseState.currentCaseId && location.pathname === "/case" && (
                        <Box display="flex" justifyContent="flex-end" width="100%">
                            <Fab
                                variant="extended"
                                color="secondary"
                                className={classes.fab}
                                onClick={() => handleClickCloseCaseTab(null, caseState.currentCaseId)}
                            >
                                Exit Case
                            </Fab>
                        </Box>
                    )}
                    <Box>
                        {(caseState.searchCriteria.displayCaseSearchInTab || accountState.searchCriteria.displayAccountSearchInTab) && (
                            <Box
                                style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                }}
                            >
                                {caseState.searchCriteria.displayCaseSearchInTab && (
                                    <MuiIconButton variant="outlined" color="primary" onClick={handleTabChangeToCaseSearch}>
                                        Case Search
                                        <CloseIcon
                                            fontSize="small"
                                            style={{
                                                position: "absolute",
                                                right: -2,
                                                top: 0,
                                            }}
                                            onClick={(e) => closeCaseSearchTab(e)}
                                        />
                                    </MuiIconButton>
                                )}
                                {accountState.searchCriteria.displayAccountSearchInTab && (
                                    <MuiIconButton variant="outlined" color="primary" onClick={handleTabChangeToAccountSearch}>
                                        Account Search
                                        <CloseIcon
                                            fontSize="small"
                                            style={{
                                                position: "absolute",
                                                right: -2,
                                                top: 0,
                                            }}
                                            onClick={(e) => closeAccountSearchTab(e)}
                                        />
                                    </MuiIconButton>
                                )}
                            </Box>
                        )}
                    </Box>

                    <Box display="flex">
                        {(caseIds.length > 0 || accountIds.length > 0) && (
                            <Box display="flex">
                                <AppBar position="static" color="default">
                                    <Tabs
                                        variant="scrollable"
                                        scrollButtons="on"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        value={currentCaseTab}
                                        style={{
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                        }}
                                    >
                                        {caseIds.map((caseId, index) => (
                                            <CaseTab
                                                key={"c_" + caseId}
                                                tabTitle={"Case" + caseState.cases[caseId].caseSummary.caseId}
                                                tooltipText={caseState.cases[caseId].caseSummary.description}
                                                index={index}
                                                id={caseId}
                                                handleTabChange={handleCaseTabChange}
                                                closeTab={handleClickCloseCaseTab}
                                            />
                                        ))}
                                        {accountIds.map((accountId, index) => (
                                            <CaseTab
                                                key={"a_" + accountId}
                                                tabTitle={accountState.accounts[accountId].name}
                                                index={index}
                                                id={accountId}
                                                handleTabChange={handleAccountTabChange}
                                                closeTab={closeAccountTab}
                                            />
                                        ))}
                                    </Tabs>
                                </AppBar>
                            </Box>
                        )}
                    </Box>

                    {children}
                </MainContent>
                <Footer />
            </AppContent>
        </Root>
    );
};

const mapStateToProps = (state) => ({
    caseState: state.caseReducer,
    accountState: state.accountReducer,
    configState: state.configReducer,
});
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentCase: (caseId) => dispatch(setCurrentCaseAction(caseId)),
        removeCaseTab: (caseId) => dispatch(removeCaseTabAction(caseId)),
        setDisplayCaseSearchInTab: (display) => dispatch(setDisplayCaseSearchInTab(display)),
        setCriteriaAccountName: (accountName) => dispatch(setCriteriaAccountName(accountName)),
        setCriteriaAdviserId: (adviserId) => dispatch(setCriteriaAdviserId(adviserId)),
        setCriteriaCaseTypeId: (caseTypeId) => dispatch(setCriteriaCaseTypeId(caseTypeId)),
        setCriteriaPartnershipId: (partnershipId) => dispatch(setCriteriaPartnershipId(partnershipId)),
        setCriteriaPartnerNumber: (partnerNumber) => dispatch(setCriteriaPartnerNumber(partnerNumber)),
        setCriteriaEmployee: (employee) => dispatch(setCriteriaEmployee(employee)),
        setCriteriaModifiedDateFrom: (modifiedDateFrom) => dispatch(setCriteriaModifiedDateFrom(modifiedDateFrom)),
        setCriteriaModifiedDateTo: (modifiedDateTo) => dispatch(setCriteriaModifiedDateTo(modifiedDateTo)),
        setCriteriaCreatedDateFrom: (createddateFrom) => dispatch(setCriteriaCreatedDateFrom(createddateFrom)),
        setCriteriaCreatedDateTo: (createddateTo) => dispatch(setCriteriaCreatedDateTo(createddateTo)),
        setDisplayAccountSearchInTab: (display) => dispatch(setDisplayAccountSearchInTab(display)),
        setCurrentAccount: (accountId) => dispatch(setCurrentAccountAction(accountId)),
        removeAccountTab: (accountId) => dispatch(removeAccountTabAction(accountId)),
        setAccountCriteriaAccountName: (accountName) => dispatch(setAccountCriteriaAccountName(accountName)),
        setAccountCriteriaPostCode: (postCode) => dispatch(setAccountCriteriaPostCode(postCode)),
        setAccountCriteriaPartnerNumber: (partnerNumber) => dispatch(setAccountCriteriaPartnerNumber(partnerNumber)),
        setAccountCriteriaPartnershipId: (partnershipId) => dispatch(setAccountCriteriaPartnershipId(partnershipId)),
        setAccountCriteriaStatus: (status) => dispatch(setAccountCriteriaStatus(status)),
        setConfirmCompleteTaskInfo: (isOpen, channelType, sid, isEmailAllocated, taskSid) =>
            dispatch(setConfirmCompleteTaskInfo(isOpen, channelType, sid, isEmailAllocated, taskSid)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(DashboardLayout));
