import * as types from "../constants";
import axios from "../../plugins/axios";
import { setSnackAction } from "../actions/snackActions";
import _ from "lodash";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const initialState = {
    account: null,
    //the  accounts array was added so that we can open multiple accounts and display them as tabs
    //"accounts" allows us to keep a track of which account records are open
    accounts: {},
    currentAccountId: null,
    searchCriteria: {
        displayAccountSearchInTab: false,
        accountName: "",
        partnerNumber: "",
        postCode: "",
        partnershipId: null,
        accountStatus: "",
        adviserId: "",
    },
    callbackRequest: {
        data: {
            contactId: null,
            name: "",
            phone: "",
        },
        isOpen: false,
    },
};

export const actionCreators = {
    getAccount: (accountId, history) => async (dispatch, getState) => {
        var response = await axios.get(`${apiUrl}/getaccount/${accountId}`);

        if (response.status === 204) {
            dispatch(setSnackAction("Account not found!", "warning"));
        } else {
            dispatch({
                type: types.SET_ACCOUNT,
                account: response.data,
            });
            dispatch({
                type: types.ADD_ACCOUNT,
                accountId: accountId,
                account: response.data,
            });

            history.push("/account");
        }
    }
};

const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case types.SET_ACCOUNT: {
            return {
                ...state,
                account: action.account,
            };
        }

        case types.SET_ACCOUNT_NOTE: {
            let a = state.accounts[action.accountId];

            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    [action.accountId]: {
                        ...a,
                        noteText: action.text,
                    },
                },
            };
        }

        case types.SET_ACC_CRITERIA_ACCOUNT_NAME: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    accountName: action.accountName,
                },
            };
        }

        case types.SET_ACC_CRITERIA_POST_CODE: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    postCode: action.postCode,
                },
            };
        }

        case types.SET_ACC_CRITERIA_PARTNER_NUMBER: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    partnerNumber: action.partnerNumber,
                },
            };
        }

        case types.SET_ACC_CRITERIA_PARTNERSHIP_ID: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    partnershipId: action.partnershipId,
                },
            };
        }

        case types.SET_ACC_CRITERIA_ACCOUNT_STATUS: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    accountStatus: action.accountStatus,
                },
            };
        }

        case types.SET_DISPLAY_ACCOUNT_SEARCH_IN_TAB: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    displayAccountSearchInTab: action.display,
                },
            };
        }

        case types.SET_CURRENT_ACCOUNT: {
            return {
                ...state,
                currentAccountId: action.accountId,
            };
        }

        case types.REMOVE_ACCOUNT_TAB: {
            let accounts = _.omit(state.accounts, action.accountId);

            let keys = Object.keys(accounts);

            return {
                ...state,
                accounts: accounts,
                currentAccountId:
                    keys.length === 0 ? null : accounts[keys[0]].accountId,
            };
        }

        case types.ADD_ACCOUNT: {
            //console.log('account_being_added : ', action.account);
            return {
                ...state,
                currentAccountId: action.accountId,
                accounts: {
                    ...state.accounts,
                    [action.accountId]: {
                        ...action.account,
                        noteEdited: false,
                    },
                },
            };
        }

        case types.SET_ACCOUNT_NOTE_EDITED: {
            let a = state.accounts[action.accountId];

            return {
                ...state,
                accounts: {
                    ...state.accounts,
                    [action.accountId]: {
                        ...a,
                        noteEdited: action.edited,
                    },
                },
            };
        }

        case types.SET_ACC_CRITERIA_ADVISER_ID: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    adviserId: action.adviserId,
                },
            };
        }

        case types.SET_CALLBACK_REQUEST_DATA: {
            return {
                ...state,
                callbackRequest: {
                    ...state.callbackRequest,
                    data: {
                        contactId: action.payload.contactId,
                        name: action.payload.name,
                        phone: action.payload.phone,
                    },
                },
            };
        }

        case types.SET_CALLBACK_REQUEST_OPEN: {
            return {
                ...state,
                callbackRequest: {
                    ...state.callbackRequest,
                    isOpen: action.isOpen,
                },
            };
        }

        default:
            return state;
    }
};

export default reducer;
