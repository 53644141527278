import React, { useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { connect } from "react-redux";
import axios from "../plugins/axios";

import {
    Divider as MuiDivider,
    Typography,
    Grid,
    Paper,
    Tabs,
    Tab,
    makeStyles,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import TabPanel from "../components/TabPanel";
import AccountFiles from "../components/AccountFiles";
import {
    setAccountNote,
    setAccountNoteEdited,
} from "../redux/actions/accountActions";
import { setSnackAction } from "../redux/actions/snackActions";

import AccountDetailsCard from "../cards/account/AccountDetailsCard";
import ClientNotesCard from "../cards/account/ClientNotesCard";
import AccountCaseTypesCard from "../cards/account/AccountCaseTypesCard";
import AccountThermometerCard from "../cards/account/AccountThermometerCard";
import ELTeamCard from "../cards/account/ELTeamCard";
import EWTeamCard from "../cards/account/EWTeamCard";
import RecentCasesCard from "../cards/account/RecentCasesCard";
import AccountContactsCard from "../cards/account/AccountContactsCard";
import accountFileTypes from "../constants/accountFileTypes";
import documentRequestTypes from "../constants/documentRequestTypes";
import ContractSummaryCard from "../cards/account/ContractSummaryCard";
import LeiEdiDetailsCard from "../cards/account/LeiEdiDetailsCard";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme) => ({
    tabPaper: {
        marginTop: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Account = ({
    accountState,
    configState,
    setAccountNote,
    setSnack,
    setAccountNoteEdited,
    caseState,
}) => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);
    const [saving, setSaving] = useState(false);

    const accountId = accountState.currentAccountId;
    const account = accountState.accounts[accountId];
    const noteEdited = account && account.noteEdited;

    const handleNoteChange = (e) => {
        if (!noteEdited) setAccountNoteEdited(true, accountId);
        setAccountNote(e.target.value, accountId);
    };

    const handleTabChange = (e, newValue) => {
        setCurrentTab(newValue);
    };

    const saveNote = async () => {
        setSaving(true);
        let response = await axios.post(`${apiUrl}/updateaccountnote`, {
            accountId: account.accountId,
            noteText: account.noteText,
        });
        if (response.status === 200) {
            setSnack("Successfully saved note!", "success");
        } else {
            setSnack("Failed to save note", "error");
        }
        setSaving(false);
        setAccountNoteEdited(false, accountId);
    };

    if (!account) {
        return (
            <React.Fragment>
                <Helmet title="Account not loaded" />
                <Typography
                    variant="h3"
                    gutterBottom
                    display="inline"
                ></Typography>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet title={`Account ${account.name}`} />
            <Paper className={classes.tabPaper}>
                <Tabs value={currentTab} onChange={handleTabChange}>
                    <Tab label="Details" />
                    <Tab label="Contracts and Handbooks" />
                </Tabs>
            </Paper>
            <Divider my={6} />
            <TabPanel value={currentTab} index={0}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={3}>
                        <AccountDetailsCard
                            account={account}
                            sectors={configState.sectors}
                            setSnack={setSnack}
                        />
                        <AccountThermometerCard account={account} />
                        <AccountCaseTypesCard account={account} />
                        <ClientNotesCard
                            noteText={account.noteText || ""}
                            handleNoteChange={handleNoteChange}
                            saveNote={saveNote}
                            saving={saving}
                            noteEdited={noteEdited}
                        />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <ContractSummaryCard
                                    accountId={account.accountId}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <LeiEdiDetailsCard
                                    accountId={account.accountId}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <AccountContactsCard
                                    account={account}
                                    caseState={caseState}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} md={6}>
                                <ELTeamCard elTeam={account.elTeam} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <EWTeamCard
                                    cxCoordinator={account.cxCoordinator}
                                    clientManager={account.clientManagerDTO}
                                    hsConsultant={account.hsConsultant}
                                    salesRep={account.salesRep}
                                    hrPrimaryAdvisor={account.hrPrimaryAdvisor}
                                    hrSecondaryAdvisor={account.hrSecondaryAdvisor}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <RecentCasesCard
                                    accountId={account.accountId}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <AccountFiles
                    accountId={account.accountId}
                    fileType={accountFileTypes.ContractsAndHandbooks}
                    documentType={documentRequestTypes.EW_DOCUMENTS}
                />
            </TabPanel>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    accountState: state.accountReducer,
    configState: state.configReducer,
    caseState: state.caseReducer,
});

const mapDispatchToProps = (dispatch) => ({
    setAccountNote: (text, accountId) =>
        dispatch(setAccountNote(text, accountId)),
    setSnack: (message, severity) =>
        dispatch(setSnackAction(message, severity)),
    setAccountNoteEdited: (edited, accountId) =>
        dispatch(setAccountNoteEdited(edited, accountId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
