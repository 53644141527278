import React, { useState } from "react";
import { Grid, Button, Typography, Icon, makeStyles } from "@material-ui/core";
import { WarningTwoTone as WarningIcon } from "@material-ui/icons";
import worknestBackground from "../images/worknest-background.png";
import LandingLayout from "../layouts/LandingLayout";
import { useAuth } from "../contexts/authContext";
import caseNestLogo from "../images/CaseNest_Purple_Positive_RGB.svg";

const msLogoUrl = `${process.env.PUBLIC_URL}/static/img/ms-logo.svg`;

const logoWidth = "300px";

const useStyles = makeStyles((theme) => ({
    microsoftLoginButton: {
        backgroundColor: "#2f2f2f",
        minHeight: "40px",
        borderRadius: 0,
        width: "100%",
        maxWidth: logoWidth,
        fontWeight: 600,
        fontSize: "15px",
        fontFamily: "Segoe UI, Arial, sans-serif",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#2f2f2f",
        },
        marginLeft: "auto",
        marginRight: "auto",
        lineHeight: "normal",
    },
    logo: {
        width: logoWidth,
        marginBottom: theme.spacing(8),
    },
    greeting: {
        textAlign: "center",
        marginBottom: theme.spacing(8),
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
}));

const LoginButtonIcon = ({ svgUrl }) => {
    const classes = useStyles();
    return (
        <Icon classes={{ root: classes.buttonIcon }}>
            <img src={svgUrl} />
        </Icon>
    );
};

const LandingPage = () => {
    const classes = useStyles();
    const [isChromeBrowser] = useState(true);
    const { isLoading, userProfileId, loginViaMicrosoft } = useAuth();

    const login = async () => {
        try {
            localStorage.setItem("redirect", window.location.pathname);
            await loginViaMicrosoft();
        } catch (error) {
            console.error(error);
        }
    };

    const LoginButton = () => {
        if (isLoading)
            return (
                <Grid container justify="center" style={{ padding: 20 }}>
                    <Typography variant="h6">Checking login</Typography>
                </Grid>
            );

        if (userProfileId === 0)
            return (
                <Grid container justify="center" style={{ padding: 20 }}>
                    <Typography variant="h6">
                        Valid login found.
                        <br />
                        User must be added in Salesforce.
                        <br />
                        Please contact IT Support.
                    </Typography>
                </Grid>
            );

        if (!isChromeBrowser)
            return (
                <Grid container justify="center" alignItems="center" style={{ padding: 20, align: "center" }}>
                    <Typography border="2px" align="center" variant="h4">
                        <WarningIcon fontSize="large" color="red" />
                    </Typography>
                    <Typography align="center" variant="h4">
                        Please use the Google Chrome browser
                    </Typography>
                </Grid>
            );

        return (
            <Button
                variant="contained"
                className={classes.microsoftLoginButton}
                classes={{ startIcon: classes.startIcon }}
                onClick={login}
                startIcon={<LoginButtonIcon svgUrl={msLogoUrl} />}
                data-cy="microsoft_sign_in"
            >
                Sign in with Microsoft
            </Button>
        );
    };

    return (
        <LandingLayout>
            <Grid container style={{ height: "100vh" }} justify="space-between">
                <Grid item xs={12} sm={6}>
                    <img
                        src={worknestBackground}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                        alt="Legal"
                    />
                </Grid>
                <Grid container item xs={12} sm={6} alignItems="center" direction="column" justify="center" style={{ padding: 10 }}>
                    <Grid item className={classes.form}>
                        <img className={classes.logo} src={caseNestLogo} alt="logo" />
                        <Typography className={classes.greeting} variant="h3">
                            Welcome
                        </Typography>
                        <LoginButton />
                    </Grid>
                </Grid>
            </Grid>
        </LandingLayout>
    );
};

export default LandingPage;
