import React, { useEffect, useState, useCallback } from "react";
import axios from "../plugins/axios";

import { Button } from "@material-ui/core";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const Subscription = () => {
    const [info, setInfo] = useState(null);

    const fetch = useCallback(async () => {
        var response = await axios.get(`${apiUrl}/subscription`);
        setInfo(response.data);
    }, [setInfo]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    if (!info) return <h1>Loading...</h1>;

    const subscribe = async () => {
        setInfo(null);
        try {
            var response = await axios.get(`${apiUrl}/subscribe`);
            setInfo(response.data);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <React.Fragment>
            <p>{info}</p>
            <Button variant="contained" onClick={subscribe}>
                Create / Renew
            </Button>
        </React.Fragment>
    );
};

export default Subscription;
