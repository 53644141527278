import React, { useCallback, useEffect, useMemo } from "react";
import { Redirect, useParams } from "react-router-dom";
import useCaseHistoryExportStatus from "../hooks/queries/useCaseHistoryExportStatus";
import { CircularProgress, makeStyles } from "@material-ui/core";
import CaseHistoryExportBody from "../components/CaseHistoryExportBody";
import { Cancel, GetApp, HourglassEmpty } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { downloadFileFromBytes } from "../utils/fileUtils";
import caseHistoryExportStatus from "../constants/caseHistoryExportStatus";
import caseService from "../services/caseService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    downloadLink: {
        color: theme.palette.secondary.main,
        textDecoration: "underline",
        cursor: "pointer",
    },
    icon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.light,
        borderRadius: "30px",
        height: "60px",
        width: "60px",
        padding: "12px",
    },
}));

const expiredExportBody =
    "Sorry, it looks like this case history export has expired. The export is only available to download for 24 hours after creation. Please initiate a new case history export if you wish to download the case history.";
const unauthorisedExportBody =
    "Sorry, you don't have permission to download the case history export. It looks like you're not authorised to access this case.";

const CaseHistoryExport = () => {
    const classes = useStyles();
    const { guid } = useParams();
    const { data, isLoading, error, isError } = useCaseHistoryExportStatus({
        guid,
    });

    const isUnauthorised = useMemo(
        () => error?.response?.status === 401,
        [error]
    );

    const downloadExportFromBytes = useCallback(() => {
        if (data?.status !== caseHistoryExportStatus.AVAILABLE) return;

        const downloadAsync = async () => {
            try {
                const bytes = await caseService.downloadCaseHistoryExport({
                    guid,
                });
                downloadFileFromBytes({
                    bytes,
                    fileName: `Case_${data.caseId}_PDF_and_Attachments.zip`,
                    fileType: "application/zip",
                });
            } catch (e) {
                console.error(e);
            }
        };

        downloadAsync();
    }, [data]);

    useEffect(() => {
        downloadExportFromBytes();
    }, [downloadExportFromBytes]);

    const downloadExportBody = (
        <span>
            If your download does not start automatically{" "}
            <span
                className={classes.downloadLink}
                onClick={downloadExportFromBytes}
            >
                click here to download manually
            </span>
        </span>
    );

    if (isLoading)
        return (
            <div className={classes.root}>
                <CircularProgress />
            </div>
        );

    if (isError && !isUnauthorised)
        return (
            <div className={classes.root}>
                <Alert variant="outlined" severity="error">
                    {error?.message || "Could not fetch case history export"}
                </Alert>
            </div>
        );

    if (data?.status === caseHistoryExportStatus.PENDING)
        return <Redirect to="/" />;

    return (
        <div className={classes.root}>
            <CaseHistoryExportBody
                icon={
                    data?.status === caseHistoryExportStatus.EXPIRED ? (
                        <HourglassEmpty
                            className={classes.icon}
                            fontSize="large"
                        />
                    ) : isUnauthorised ? (
                        <Cancel className={classes.icon} fontSize="large" />
                    ) : (
                        <GetApp className={classes.icon} fontSize="large" />
                    )
                }
                heading={
                    data?.status === caseHistoryExportStatus.AVAILABLE
                        ? "Downloading"
                        : null
                }
                subHeading={
                    data?.status === caseHistoryExportStatus.AVAILABLE
                        ? `Case_${data?.caseId}_PDF_and_Attachments.zip`
                        : null
                }
                body={
                    data?.status === caseHistoryExportStatus.EXPIRED
                        ? expiredExportBody
                        : isUnauthorised
                        ? unauthorisedExportBody
                        : downloadExportBody
                }
            />
        </div>
    );
};

export default CaseHistoryExport;
