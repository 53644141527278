import * as types from '../constants';

export function setCreateCaseAccount(account) {
	return {
		type: types.SET_CREATE_CASE_ACCOUNT,
		account
	}
}

export function setActiveStep(step) {
	return {
		type: types.SET_ACTIVE_STEP,
		step
	}
}

export function setCreateCaseContact(contact) {
	return {
		type: types.SET_CREATE_CASE_CONTACT,
		contact
    }
}

export function setCreateCaseAdvisor(advisor) {
	return {
		type: types.SET_CREATE_CASE_ADVISOR,
		advisor
	}
}

export function resetCreateCase() {
	return {
		type: types.RESET_CREATE_CASE
    }
}