// TODO: Move this server side
import { Configuration, OpenAIApi } from "openai";
import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const configuration = new Configuration({
    apiKey: "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",
});

const openai = new OpenAIApi(configuration);

const createCompletion = async ({
    partner,
    subject,
    conversation,
    currentAiModel,
    emailId,
}) => {
    try {
        const completion = await openai.createCompletion({
            model: currentAiModel,
            prompt: `Partner:${partner}\n\nSubject:${subject}\n\n###\n\n${conversation}\r\n\r\nAdviser:`,
            temperature: 0,
            max_tokens: 1000,
            stop: ["###END###"],
        });
        return completion.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const createChatCompletion = async (chatItems) => {
    try {
        const messages = [
            { role: "system", content: "You are an expert Employment Law Adviser specialising in UK Employment Law." },
            ...chatItems.sort((a, b) => new Date(a.date) - new Date(b.date)).map(item => ({
                role: item.direction === 0 ? "user" : "assistant",
                content: item.plainText,
            }))
        ];
        
        const chatCompletion = await openai.createChatCompletion({
            model: "gpt-3.5-turbo",
            messages,
        });
           
        return chatCompletion.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const saveAiThread = async ({ localThread, messages }) => {
    try {
        await axios.post(`${apiUrl}/saveaithread`, {
            ...localThread,
            messages,
        });
    } catch (e) {
        console.error(e);
        return Promise.reject(e);
    }
};

const openAiService = {
    createCompletion,
    createChatCompletion,
    saveAiThread,
};

export default openAiService;
